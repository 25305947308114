<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>SE CONNECTER</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>SE CONNECTER</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-area pt-5">
    <div class="container">
        <div class="login-page">
            <div class="form">
                <form
                    class="login-form"
                    [formGroup]="form"
                    (ngSubmit)="onSubmit()"
                >
                    <div class="form-group text-start">
                        <label for="username">Adresse électronique</label>
                        <input
                            class="form-control"
                            type="email"
                            formControlName="username"
                            placeholder="Saisissez votre email"
                        />
                    </div>

                    <div class="form-group text-start">
                        <label for="password">Mot de passe</label>
                        <input
                            type="password"
                            class="form-control"
                            placeholder="password"
                            formControlName="password"
                            placeholder="Saisissez votre mot de passe"
                        />
                    </div>

                    <div class="form-group text-end">
                        <a class="text-default" routerLink="/forgot-password">Mot de passe oublié?</a>
                    </div>

                    <re-captcha
                        class="w-100"
                        id="recaptcha"
                        formControlName="recaptcha"
                    ></re-captcha>

                    <button class="mt-5 btn btn-main" [disabled]="form.invalid || loading">
                        CONNEXION <i *ngIf="loading" class="icofont-spinner-alt-1 icofont-spin"></i>
                    </button>
                    <p class="message mt-4">
                        N'avez-vous pas encore de compte?
                        <a class="text-default" routerLink="/create-account">S'enregistrer ici</a>
                    </p>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- <app-loading [loading]="loading" /> -->
