import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
import { AppSettingService } from "src/services/app-setting/app-setting.service";
import { AuthService } from "src/services/auth/auth.service";
import { User } from "src/models/user.model";
import { UserService } from "src/services/user/user.service";
import { PrefectureService } from "src/services/prefecture/prefecture.service";
import { Prefecture } from "src/models/prefecture";
import { CommmuneService } from "src/services/commune/commmune.service";
import { RegionService } from "src/services/region/region.service";
import { Commune } from "src/models/commune";
import { Region } from "src/models/region";
import { CountriesService } from "src/services/countries/countries.service";
import { Countries } from "src/models/countries";
import { CategoriesService } from "src/services/categories/categories.service";
import { Categories } from "src/models/categories.model";
import { StaffService } from "src/services/staff/staff.service";
import { Staff } from "src/models/staff.model";
import { FaqService } from "src/services/faq/faq.service";
import { Faq } from "src/models/faq.model";
import { ServiceWorkerModule, SwUpdate } from "@angular/service-worker";
import Swal from "sweetalert2";

// declare let $: any;

import * as $ from 'jquery';
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;
    currentUser: User;
    errorLoading: boolean;
    status: number;

    constructor(
        private router: Router,
        public authService: AuthService,
        private userService: UserService,
        public appSettingService: AppSettingService,
        private _elementRef: ElementRef,
        private prefectureService: PrefectureService,
        private communeService: CommmuneService,
        private regionService: RegionService,
        private countryService: CountriesService,
        private categoryService: CategoriesService,
        private staffService: StaffService,
        private faqService: FaqService,
        private swUpdate: SwUpdate,
    ) { }

    ngOnInit() {
        this._elementRef.nativeElement.removeAttribute("ng-version");

        // if (this.swUpdate.isEnabled) {
        //     this.swUpdate.versionUpdates.subscribe(() => {
        //         this.swUpdate.activateUpdate().then(() => document.location.reload());
        //     });
        // }

        // if (this.swUpdate.isEnabled) {
        //     this.swUpdate.versionUpdates.subscribe(() => {
        //         Swal.fire({
        //             title: 'Mise à jour',
        //             text: "Vous utilisez une ancienne version de la plateforme de la BSTP. Voulez-vous effectuer la mise à jour de la dernière version ?",
        //             iconHtml: '<img src="./assets/img/shape/1.png" width="150" height="150">',
        //             customClass: {
        //                 icon: 'no-border'
        //             },
        //             showCancelButton: true,
        //             confirmButtonColor: "#006481",
        //             color: "#006481",
        //             iconColor: "#006481",
        //             cancelButtonColor: "#d33",
        //             confirmButtonText: 'Mettre à jour',
        //             cancelButtonText: 'Annuler',
        //             allowOutsideClick: () => false,
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 window.location.reload();
        //             }
        //         });
        //     });
        // }

        // $('.carousel').carousel({
        //     interval: 2000
        // })

        this.recallJsFuntions();

        this.appSettingService
            .get()
            .subscribe({
                next: (data: any) => {
                    this.appSettingService.update(data[0]);

                    this.errorLoading = true;

                    this.prefectureService.list().subscribe((prefectures: Array<Prefecture>) => this.prefectureService.update(prefectures));
                    this.communeService.list().subscribe((communes: Array<Commune>) => this.communeService.update(communes));
                    this.regionService.list().subscribe((regions: Array<Region>) => this.regionService.update(regions));
                    this.countryService.list().subscribe((countries: Array<Countries>) => this.countryService.update(countries));
                    this.categoryService.list().subscribe((categories: Array<Categories>) => this.categoryService.update(categories));
                    this.staffService.list().subscribe((staffs: Array<Staff>) => this.staffService.update(staffs));
                    this.faqService.list().subscribe((faqs: Array<Faq>) => this.faqService.update(faqs));
                },
                error: error => {
                    this.errorLoading = false;
                    if (error.status == 504) {
                        this.status = 504;
                    } 
                    console.error(error)
                },
            });

        if (this.authService.isAuthenticated()) {
            this.userService
                .currentUser()
                .subscribe((data: User) => this.authService.update(data));
        }
    }

    ngOnDestroy(): void {
        
    }

    recallJsFuntions() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                $(".loader").fadeIn("slow");
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                $.getScript("../assets/js/custom.js");
                $(".loader").fadeOut("slow");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}
