<div
    id="carouselExampleInterval"
    class="carousel slide"
    data-bs-ride="carousel"
>
    <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="4000">
            <div class="banner-area banner-area-three p-0">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="banner-text">
                                <h1>Devenir <span>Fournisseur</span></h1>
                                <p style="text-align: justify;">
                                    Promouvoir votre entreprise et trouver des
                                    offres
                                    <!-- Promouvoir votre entreprise et trouver des
                                    offres devient plus facile que jamais grâce
                                    à notre plateforme. En mettant en avant vos
                                    produits et services de manière stratégique,
                                    vous pouvez attirer l'attention de votre
                                    public cible et vous démarquer de la
                                    concurrence. De plus, en accédant à nos
                                    offres exclusives, vous pouvez développer
                                    votre réseau professionnel et saisir de
                                    nouvelles opportunités pour votre
                                    entreprise. -->
                                </p>
                                <div class="">
                                    <a class="btn btn-green" routerLink="/login"
                                        >Connexion
                                        <i class="icofont-swoosh-right"></i
                                    ></a>
                                    &nbsp;&nbsp;
                                    <a
                                        class="btn btn-red"
                                        routerLink="/create-account"
                                        >Inscription
                                        <i class="icofont-swoosh-right"></i
                                    ></a>
                                </div>
                                <!--div class="banner-form-area">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><i class="icofont-search-1"></i></label>
                                                <input type="text" class="form-control" placeholder="Job Title">
                                            </div>
                                        </div>
        
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><i class="icofont-location-pin"></i></label>
                                                <input type="text" class="form-control" placeholder="City or State">
                                            </div>
                                        </div>
                                    </div>
        
                                    <button type="submit" class="btn banner-form-btn">Search</button>
                                </form>
                            </div-->
                            </div>

                            <!-- <div class="banner-img">
                                <img
                                    src="./assets/img/home-3/banner.png"
                                    alt="Banner"
                                />
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item" data-bs-interval="4000">
            <div class="banner-area banner-area-three-1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="banner-text">
                                <h1>Devenir <span>Acheteur</span></h1>
                                <p style="text-align: justify;">
                                    Trouvez les entreprises Guinéennes validées
                                    pour exécuter votre travail
                                    <!-- Découvrez les entreprises guinéennes
                                    vérifiées pour répondre à vos besoins
                                    professionnels. En choisissant des
                                    partenaires fiables, vous pouvez être sûr
                                    que votre travail sera exécuté avec
                                    professionnalisme et excellence. Trouvez
                                    facilement les entreprises qualifiées pour
                                    vos projets et établissez des relations
                                    fructueuses pour l'avenir. -->
                                </p>
                                <div class="">
                                    <a class="btn btn-green" routerLink="/login"
                                        >Connexion
                                        <i class="icofont-swoosh-right"></i
                                    ></a>
                                    &nbsp;&nbsp;
                                    <a
                                        class="btn btn-red"
                                        routerLink="/create-account"
                                        >Inscription
                                        <i class="icofont-swoosh-right"></i
                                    ></a>
                                </div>

                                <!--div class="banner-form-area">
                                    <form>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label><i class="icofont-search-1"></i></label>
                                                    <input type="text" class="form-control" placeholder="Job Title">
                                                </div>
                                            </div>
            
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label><i class="icofont-location-pin"></i></label>
                                                    <input type="text" class="form-control" placeholder="City or State">
                                                </div>
                                            </div>
                                        </div>
            
                                        <button type="submit" class="btn banner-form-btn">Search</button>
                                    </form>
                                </div-->
                            </div>

                            <!-- <div class="banner-img">
                                <img
                                    src="./assets/img/home-3/banner.png"
                                    alt="Banner"
                                />
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleInterval"
        data-bs-slide="prev"
    >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleInterval"
        data-bs-slide="next"
    >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>

<div class="counter-area pt-5 pb-5" *ngIf="appSettingService.value != null">
    <div class="container">
        <div class="row">
            
            <div class="col-md-6 col-sm-4 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-curriculum"></i>
                    <span class="counter">
                        +{{ appSettingService.value.total_supplier }}
                    </span>
                    <p>Fournisseurs</p>
                </div>
            </div>

            <div class="col-md-6 col-sm-4 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <span class="counter">
                       +{{ appSettingService.value.total_buyer }}
                    </span>
                    <p>Acheteurs</p>
                </div>
            </div>

            <div class="col-md-6 col-sm-4 col-lg-3">
                <div class="counter-item">
                    <i
                        class="flaticon-businessman-paper-of-the-application-for-a-job"
                    ></i>
                    <span class="counter">
                        +{{ appSettingService.value.total_request }}
                    </span>
                    <p>Appels d'offre</p>
                </div>
            </div>

            <div class="col-md-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <span class="counter">+{{ appSettingService.value.total_organisme }}</span>
                    <p>Organismes de soutien</p>
                </div>
            </div>

            <!-- <div class="col-md-6 col-sm-3 col-lg-2">
                <div class="counter-item">
                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                    <h3><span class="counter">14</span>k+</h3>
                    <p>Formations</p>
                </div>
            </div> -->
            <!-- <div class="col-md-6 col-sm-3 col-lg-2">
                <div class="counter-item">
                    <i class="flaticon-employee"></i>
                    <h3><span class="counter">14</span>k+</h3>
                    <p>Formations</p>
                </div>
            </div> -->

        </div>
    </div>
</div>

<!--section class="category-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Choose Your Desire Category</h2>
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item">
                    <i class="flaticon-settings"></i>
                    <a routerLink="/">Technical Support</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-two">
                    <i class="flaticon-layers"></i>
                    <a routerLink="/">Business Development</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-three">
                    <i class="flaticon-house"></i>
                    <a routerLink="/">Real Estate Business</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-four">
                    <i class="flaticon-analysis"></i>
                    <a routerLink="/">Share Maeket Analysis</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-three">
                <div class="category-item category-five">
                    <i class="flaticon-sun"></i>
                    <a routerLink="/">Weather & Environment</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-six">
                    <i class="flaticon-hand"></i>
                    <a routerLink="/">Finance & Banking Service</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-seven">
                    <i class="flaticon-neural"></i>
                    <a routerLink="/">IT & Networing Sevices</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-eight">
                    <i class="flaticon-dish"></i>
                    <a routerLink="/">Restaurant Services</a>
                </div>
            </div>

            <div class="col-sm-3 offset-sm-3 offset-lg-0 col-lg-3 category-border-two">
                <div class="category-item category-nine">
                    <i class="icofont-fire-burn"></i>
                    <a routerLink="/">Defence  & Fire Service</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="category-item category-ten">
                    <i class="flaticon-truck"></i>
                    <a routerLink="/">Home Delivery Services</a>
                </div>
            </div>
        </div>
    </div>
</section-->

<div class="portal-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="portal-item">
                    <div class="row">
                        <div class="col-lg-7">
                            <img src="./assets/img/upload/home/section-1.png" alt="Portal" />
                        </div>

                        <div class="col-lg-5">
                            <img src="./assets/img/upload/home/section-2.png" alt="Portal" />
                        </div>
                    </div>

                    <div class="portal-trusted">
                        <span>100% Garantie</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="portal-item portal-right">
                    <h2>BSTP offre des opportunités convertibles en direct</h2>
                    <p style="text-align: justify;">
                        BSTP vous ouvre les portes vers des opportunités
                        commerciales directes, qu'elles émanent d'entreprises
                        locales ou de multinationales. En plus, nous vous
                        offrons un accompagnement technique pour renforcer vos
                        compétences et vous conformer aux normes
                        internationales.
                    </p>
                    <div class="common-btn">
                        <a class="login-btn" routerLink="/login"
                            >Connexion <i class="icofont-swoosh-right"></i
                        ></a>
                        <a class="sign-up-btn" routerLink="/create-account"
                            >Inscription <i class="icofont-swoosh-right"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="create-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="create-item">
                    <h2>Créez votre profil pour découvrir des milliers d'opportunités d'affaires, développer votre carrière et votre emploi !</h2>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="create-item">
                    <div class="create-btn">
                        <a routerLink="/create-account">Adhérez maintenant</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--section class="job-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Recent Jobs</h2>
        </div>

        <div class="sorting-menu">
            <ul> 
                <li class="filter active" data-filter="all">All</li>
                <li class="filter" data-filter=".web">New</li>
                <li class="filter" data-filter=".ui">Featured</li>
                <li class="filter" data-filter=".branding">Recent</li>
                <li class="filter" data-filter=".ux">Full Time</li>
                <li class="filter" data-filter=".wp">Part Time</li>
            </ul>
        </div>

        <div id="container">
            <div class="row">
                <div class="col-lg-6 mix web ui">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/1.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>UI/UX Designer</h3>
                                <a routerLink="/company-details">Winbrans.com</a>
                                <ul>
                                    <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Full Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix ui web">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/2.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Android Developer</h3>
                                <a routerLink="/company-details">Infiniza.com</a>
                                <ul>
                                    <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Part Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix branding wp"> 
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/3.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Senior Manager</h3>
                                <a routerLink="/company-details">Glovibo.com</a>
                                <ul>
                                    <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Intern</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix ux branding">
                    <div class="job-item ">
                        <img src="assets/img/home-1/jobs/4.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Product Designer</h3>
                                <a routerLink="/company-details">Bizotic.com</a>
                                <ul>
                                    <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Part Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix wp web">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/5.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Digital Marketer</h3>
                                <a routerLink="/company-details">Hotelzo.com</a>
                                <ul>
                                    <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street, Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Intern</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix web ui">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/6.png" alt="Job">
                        
                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Sales Manager</h3>
                                <a routerLink="/company-details">Gozuto.com</a>
                                <ul>
                                    <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street, Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Part Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix ux ui">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/7.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Web Developer</h3>
                                <a routerLink="/company-details">Udiza.com</a>
                                <ul>
                                    <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street, Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Full Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix branding web">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/8.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>SEO</h3>
                                <a routerLink="/company-details">Oqota.com</a>
                                <ul>
                                    <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street, Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Part Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->

<!--div class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-employee"></i>
                    <h3><span class="counter">14</span>k+</h3>
                    <p>Job Available</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-curriculum"></i>
                    <h3><span class="counter">18</span>k+</h3>
                    <p>CV Submitted</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <h3><span class="counter">9</span>k+</h3>
                    <p>Companies</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                    <h3><span class="counter">35</span>+</h3>
                    <p>Appointed to Job</p>
                </div>
            </div>
        </div>
    </div>
</div-->

<div class="popular-area pt-100 pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="popular-item">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <img src="./assets/img/upload/home/342x514.png" alt="Popular" />
                        </div>

                        <div class="col-lg-5">
                            <div class="practice-inner">
                                <img
                                    src="./assets/img/upload/home/252x306.png"
                                    alt="Popular"
                                />
                                <img
                                    src="./assets/img/upload/home/252x213.png"
                                    alt="Popular"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="popular-item popular-right">
                    <div class="section-title text-start">
                        <h2>
                            Trouver des Fournisseurs Guinéens Validés Crédibles
                        </h2>
                    </div>
                    <p style="text-align: justify; font-size: 16px; color: black;">
                        Vous avez accès à une base de données fiable et
                        exhaustive des entreprises guinéennes compétitives tout
                        en étant en mesure de faire des offres de qualité.
                        Suivre l'impact socio-économique des investissements sur
                        le développement du contenu local en termes de création
                        d'emplois et de richesse.
                    </p>

                    <!-- <div class="row popular-wrap">
                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li>
                                    <i class="flaticon-approved"></i> Trusted &
                                    Quality Job
                                </li>
                                <li>
                                    <i class="flaticon-no-money"></i> No Extra
                                    Charge
                                </li>
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li>
                                    <i class="flaticon-enterprise"></i> Top
                                    Companies
                                </li>
                                <li>
                                    <i class="flaticon-employee"></i>
                                    International Job
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!--div class="account-area account-area-two">
    <div class="container">
        <div class="row account-wrap">
            <div class="col-sm-6 col-lg-4">
                <div class="account-item">
                    <i class="flaticon-approved"></i>
                    <span>Register Your Account</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="account-item">
                    <i class="flaticon-cv"></i>
                    <span>Upload Your Resume</span>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="account-item account-last">
                    <i class="flaticon-customer-service"></i>
                    <span>Apply for Dream Job</span>
                </div>
            </div>
        </div>

        <div class="banner-btn">
            <a routerLink="/create-account">Create Your Profile</a>
            <a routerLink="/submit-resume">Upload Your CV</a>
        </div>
    </div>
</div-->



<section class="companies-area ptb-100" *ngIf="appSettingService.value != null">
    <div class="container">
        <div class="section-title">
            <h2 class="text-default">VOLUMES D'ACTIVITÉS</h2>
        </div>

        <div class="owl-theme owl-carousel mt-5">
            <div class="chart-item text-center">
                <canvas id="myChart1" width="600" height="300"></canvas>
                <span class="text-default text-center" style="text-align: center; font-size: 30px; font-weight: 700;">{{ format(appSettingService.value.total_supplier) }}</span>
                <p class="text-green text-center">Nombre de PME/PMI Profilées</p>
            </div>
            <div class="chart-item text-center">
                <canvas id="myChart2" width="600" height="300"></canvas>
                <span class="text-default text-center" style="text-align: center; font-size: 30px; font-weight: 700;">1 806</span>
                <p class="text-green text-center">Nombre de PME/PMI benchmarchées</p>
            </div>
            <div class="chart-item text-center">
                <canvas id="myChart3" width="600" height="300"></canvas>
                <span class="text-default text-center" style="text-align: center; font-size: 30px; font-weight: 700;">1 806</span>
                <p class="text-green text-center">Nombre total d'emplois créés</p>
            </div>
            <div class="chart-item text-center">
                <canvas id="myChart4" width="600" height="250"></canvas><br>
                <span class="text-default text-center" style="text-align: center; font-size: 30px; font-weight: 700;">1 806</span>
                <p class="text-green text-center">Nombre de PME/PMI accompagnées</p>
            </div>
            <div class="chart-item text-center">
                <canvas id="myChart5" width="600" height="250"></canvas><br>
                <span class="text-default text-center" style="text-align: center; font-size: 30px; font-weight: 700;">{{ format(appSettingService.value.total_request) }}</span>
                <p class="text-green text-center">Nombre de mises en relation</p>
            </div>
            <div class="chart-item text-center">
                <canvas id="myChart6" width="600" height="250"></canvas><br>
                <span class="text-default text-center" style="text-align: center; font-size: 30px; font-weight: 700;">1 806</span>
                <p class="text-green text-center">Nombre de PME/PMI contractualisées</p>
            </div>
            <div class="chart-item text-center">
                <canvas id="myChart7" width="600" height="250"></canvas><br>
                <h4 class="text-default text-center" style="text-align: center; font-size: 30px; font-weight: 700;">{{ format(appSettingService.value.total_buyer) }}</h4>
                <p class="text-green text-center">Nombre d'acheteurs trouvés</p>
            </div>
            <div class="chart-item text-center">
                <canvas id="myChart8" width="600" height="250"></canvas><br>
                <span class="text-default text-center" style="text-align: center; font-size: 30px; font-weight: 700;">1 806</span>
                <p class="text-green text-center">Nombre total de contrats</p>
            </div>
            <div class="chart-item text-center">
                <canvas id="myChart9" width="600" height="250"></canvas><br>
                <span class="text-default text-center" style="text-align: center; font-size: 30px; font-weight: 700;">1 806 000 000</span>
                <p class="text-green text-center">Montant total des contrats (En milliards de GNF)</p>
            </div>

        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-170">
    <div class="container">
        <div class="section-title">
            <h2>NOS SERVICES</h2>
        </div>

        <div class="row" *ngIf="articles">
            <div class="col-sm-12 col-md-6 col-lg-6" *ngFor="let service of services">
                <div class="job-item bg-white wow fadeInUp shadow rounded-3" data-wow-delay=".3s">
                    <div class="row">
                        <div class="col-lg-1">
                            <i class="text-yellow" [ngClass]="service.icon" style="font-size: 35px;"></i>
                        </div>
                        <div class="col-lg-11">
                            <div class="align-items-center">
                                <!-- <div class="job-inner-left">
                                </div> -->
                                <h4 class="text-default">{{ service.name }}</h4>
                                <div style="text-align: justify;" [innerHTML]="service.short_description"></div>
        
                                <!-- <div class="job-inner-right">
                                    <ul>
                                        <li><a [routerLink]="['/services', service.slug]">LIRE PLUS</a></li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!--div class="app-area pb-170">
    <div class="container pb-100">
        <div class="row">
            <div class="col-lg-6">
                <div class="app-item app-left">
                    <img src="assets/img/home-1/6.png" alt="Mobile" />
                    <img src="assets/img/home-1/7.png" alt="Mobile" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="app-item">
                    <div class="section-title text-start">
                        <h2>Téléchargez l'Application Mobile</h2>
                    </div>
                    <p>
                        Découvrez une nouvelle façon de profiter de nos services
                        en téléchargeant notre application mobile. Disponible
                        sur iOS et Android, notre application vous permet de
                        rester connecté(e) à tout moment et de bénéficier d'une
                        expérience utilisateur optimale.
                    </p>
                    <div class="app-btn">
                        <a class="app-btn-one" href="#">
                            <i class="flaticon-apple"></i>
                            <span>Download on the</span>
                            <p>App Store</p>
                        </a>
                        <a class="app-btn-two" href="#">
                            <i class="flaticon-playstore"></i>
                            <span>ANDROID APP ON</span>
                            <p>Google Play</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->

<section class="blog-area pt-170 pb-170">
    <div class="container">
        <div class="section-title">
            <h2>NOS DERNIÈRES ACTUALITÉS</h2>
        </div>

        <div class="row" *ngIf="articles">
            <div class="col-sm-6 col-md-4 col-lg-3" *ngFor="let article of articles">
                <div class="blog-item wow fadeInUp" data-wow-delay=".3s">
                    <div class="blog-top">
                        <a routerLink="blog/{{ article.slug }}"><img [src]="article.media" [alt]="article.title"></a>
                        <span>{{ article.publishon_on|date:'d MMMM yyyy' }}</span>
                    </div>

                    <div class="blog-bottom">
                        <h5 class="text-green"><a routerLink="blog/{{ article.slug }}"> {{ article.title }} </a></h5>
                        <ul>
                            <li><a routerLink="blog/{{ article.slug }}">Lire Plus<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
