<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>PROCÉDEZ AU PAIEMENT</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>PROCÉDEZ AU PAIEMENT</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="post-job-area pt-5 pb-100 mt-5">
    <div class="container text-left">
        <img src="assets/guides/04.png" alt="" width="150" />
        <div class="pt-5">
            <h4>Procédez au Paiement</h4>
            <p>
                Cette étape vous permet de procéder au paiement de votre abonnement annuel qui est fixé à <strong>1 000 000 GNF</strong>.
            </p>
            <p>
                Cet abonnement permet à votre entreprise d'avoir accès aux appels d'offres, de soumissionner aux appels d'offres.
            </p>
            
            <div class="row pt-100">
                <!-- <div class="col-lg-6">
                    <button routerLink="/login" class="btn btn-outline-dark" type="reset">
                        Ignorer
                    </button>
                </div> -->
                <div class="col-lg-6">
                    <button (click)="onPayment()" [disabled]="loading" class="btn btn-main" type="submit">
                        <i class="icofont-money"></i> Procédez au Paiement <i class="icofont-arrow-right"></i> <i *ngIf="loading" class="icofont-spinner-alt-1 icofont-spin"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
