<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>CONFIRMATION DE VÉRIFICATION DE L'EMAIL</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>CONFIRMATION DE VÉRIFICATION DE L'EMAIL</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="post-job-area pt-5 pb-100 mt-5">
    <div class="container text-left">
        <img src="assets/img/success.png" alt="" width="170" />
        <div class="pt-5">
            <h4>Confirmation de Vérification de l'Email</h4>
            <p>
                Un email de confirmation vous sera envoyé pour vérifier votre
                adresse email.
            </p>
            <p>
                Après avoir confirmé votre email, vous pourrez accéder aux
                services de la BSTP (Bibliothèque Scientifique et Technique du
                Patrimoine).
            </p>
            <p>Nous vous remercions pour votre inscription.</p>
            <p>Cordialement,</p>
            <p>L'équipe de support de la BSTP</p>
            <!-- <button (click)="goToAccount()" class="btn subscribe-btn" type="submit">Accéder à mon compte</button> -->
        </div>
    </div>
</div>
