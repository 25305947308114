export function removeAt(array: Array<any> = [], key: number) {
    array.forEach((value, index) => {
        if (value.id == key) array.splice(index, 1);
    });
}

export function replaceAt(array: Array<any> = [], param: any) {
    array.forEach((value, index) => {
        if (value.id == param.id) array.splice(index, 1, param);
    });
}

export function stringToNumber(input: string) {
  var numeric = Number(input);
  return numeric;
}

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function arrondir(x) {
  return Math.round(x)
}

export function convertirEnDateLocale(dateUTC: Date): Date {
  const dateLocale = new Date(dateUTC);
  // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const dateEnLocale = dateLocale.toLocaleDateString('fr-FR');
  return new Date(dateEnLocale);
}

export function filterSearch(liste: Array<any>, termeRecherche: string): Array<any> {
  const termeRechercheMinuscules = termeRecherche.toLowerCase().trim();
  if (!termeRechercheMinuscules) {
      return liste; // Aucun terme de recherche, retourne la liste non filtrée
  }
  return liste.filter(donnee =>
      donnee.nom.toLowerCase().includes(termeRechercheMinuscules)
  );
}

export function compareArraysByKey<T, K extends keyof T>(arr1: T[], arr2: T[], key: K): boolean {
  return arr1.some(obj1 => arr2.some(obj2 => obj1[key] === obj2[key]));
}

export function startCounting(target: number = 0) {
  let count: number = 0;
  let speed: number = 1; // 1 pour lent, peut être augmenté pour accélérer
  let intervalId: any;

  // Ajuster la vitesse en fonction de la valeur du compteur
  intervalId = setInterval(() => {
    if (count < target) {
      // incrementCount();
      if (count < 100) {
        speed = 1; // Lent
      } else if (count < 500) {
        speed = 5; // Moyen
      } else if (count < 1000) {
        speed = 10; // Rapide
      } else {
        speed = 20; // Très rapide
      }
      count += speed;
    } else {
      clearInterval(intervalId); // Stopper le compteur quand la cible est atteinte
    }
    console.log(count);
    return count;
  }, 10); // L'intervalle est de 100ms, peut être ajusté pour plus de rapidité
}

// export function incrementCount() {
//   // Ajuster la vitesse d'incrémentation
//   if (count < 100) {
//     speed = 1; // Lent
//   } else if (count < 500) {
//     speed = 5; // Moyen
//   } else if (count < 1000) {
//     speed = 10; // Rapide
//   } else {
//     speed = 20; // Très rapide
//   }
//   count += speed;
// }