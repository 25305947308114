import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/services/payment/payment.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-successful',
  templateUrl: './payment-successful.component.html',
  styleUrls: ['./payment-successful.component.scss']
})
export class PaymentSuccessfulComponent implements OnInit {
  constructor(private paymentService: PaymentService) { }
  ngOnInit(): void {
    if (localStorage.getItem('pay_id')) {
      this.paymentService.verified(localStorage.getItem('pay_id'), localStorage.getItem('company_slug')).subscribe({
        next: response => {
          localStorage.removeItem('pay_id');
          localStorage.removeItem('company_slug');
        },
        error: error => {
          let message = error.error.detail || error.error.non_error_fields[0];

          console.log(message);

          Swal.fire({
            title: "Finalisation du Paiement",
            text: message,
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#006481",
            color: "#006481",
            iconColor: "#e40e20",
            cancelButtonColor: "#d33",
            confirmButtonText: "Reesayer",
            cancelButtonText: "Fermer",
          });
        }
      })
    }
  }
}
