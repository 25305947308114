import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { User } from 'src/models/user.model';
import { AuthService } from 'src/services/auth/auth.service';
import { UserService } from 'src/services/user/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  loading: boolean;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private jwtHelper: JwtHelperService,
    private authService: AuthService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      password1: this.fb.control(null, [Validators.required]),
      password2: this.fb.control(null, [Validators.required]),
      recaptcha: this.fb.control(null, [Validators.required]),
    })
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const control of Object.keys(this.form.controls)) {
        this.form.controls[control].markAsTouched();
      }
      return;
    }

    this.recaptchaV3Service
      .execute("importantAction")
      .subscribe((token: string) => {
        console.debug(`Token [${token}] generated`);
      });

    this.loading = true;
    const slug = localStorage.getItem('slug')

    const uidb64 = this.route.snapshot.params.uidb64
    const token = this.route.snapshot.params.token

    this.userService.changePassword(this.form.value, uidb64, token).subscribe({
      next: (response: User) => {
        this.loading = false;
        this.authService.update(response);
        Swal.fire({
          title: "Nouveau mot de passe",
          text: "Votre mot de passe a été changé avec succès.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#006481",
          color: "#006481",
          iconColor: "#00533e",
          cancelButtonColor: "#d33",
          confirmButtonText: "CONTINUER",
          allowOutsideClick: () => false,
        }).then((result) => {
          // const returnUrl = localStorage.getItem('role') === "supplier" || localStorage.getItem('role') === "supplier_collegue" ? "supplier/dashboard" : "buyer/dashboard";
          window.location.replace('/login');
        });
      },
      error: error => {
        this.loading = false;
        Swal.fire({
          title: "Confirmation",
          html: error.error.detail || error.error.non_field_errors[0],
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: '#006481',
          color: '#006481',
          iconColor: '#e40e20',
          cancelButtonColor: '#d33',
          confirmButtonText: 'FERMER',
        });
      }
    })
  }

}
