<!-- <div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>ERREUR DE CONNEXION</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>ERREUR DE CONNEXION</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <h1>Site en maintenance</h1>
                    <br>
                    <p style="font-size: 20px;">Notre site est actuellement en maintenance. Merci de votre patience et à bientôt !</p>
                </div>
            </div>
        </div>
    </div>
</section>