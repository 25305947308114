import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PartenaireType } from 'src/models/partenaire-type';

@Injectable({
  providedIn: 'root'
})
export class PartenaireTypeService {
  private partenairesSubject: BehaviorSubject<Array<PartenaireType>>;
  public partenaires$: Observable<Array<PartenaireType>>;

  constructor(private http: HttpClient) { 
    this.partenairesSubject = new BehaviorSubject<Array<PartenaireType>>([]);
    this.partenaires$ = this.partenairesSubject.asObservable();
  }

  public get value(): Array<PartenaireType> {
    return this.partenairesSubject.value;
  }

  update(partenaires: Array<PartenaireType>){
    this.partenairesSubject.next(partenaires);
  }

  list(): Observable<Array<PartenaireType>> {
    return this.http.get(`${environment.apiUrl}partenaire_type/`) as Observable<Array<PartenaireType>>;
  }
}
