import { Component, Input, OnInit } from '@angular/core';
import { AppSettingService } from 'src/services/app-setting/app-setting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  
  constructor(public appSettingService: AppSettingService) { }
}
