import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-subscription-success",
    templateUrl: "./subscription-success.component.html",
    styleUrls: ["./subscription-success.component.scss"],
})
export class SubscriptionSuccessComponent implements OnInit {
    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {}

    goToAccount() {
      this.router.navigateByUrl('/login');
    }
}
