import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AppSettingService } from 'src/services/app-setting/app-setting.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    public appSettingService: AppSettingService, private meta: Meta) { }

  ngOnInit(): void {
    this.meta.addTag({ name: 'description', content: "A propos de nous" });
    this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn, ' });
    this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });
  }

}
