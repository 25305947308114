import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/models/user.model';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<User>;
  public user$: Observable<User>;

  constructor(
    private http: HttpClient,
    private router: Router,
    public jwtHelper: JwtHelperService,
  ) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user$ = this.userSubject.asObservable();
  }

  public get value(): User {
    return this.userSubject.value;
  }

  update(user: User) {
    this.userSubject.next(user);
  }

  login(user: User) {
    return this.http.post(`${environment.apiUrl}auth/token/`, user);
  }

  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }

  logout() {
    Swal.fire({
      title: 'Déconnexion',
      text: 'Voulez-vous vraiment vous déconnecter ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#006481',
      color: '#006481',
      iconColor: '#006481',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: "Non, j'annule",
      allowOutsideClick: () => false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.userSubject.next(null);
        localStorage.clear();
        // this.router.navigate(['/'])
        window.location.replace('/');
      }
    });
  }


  refreshToken() {
    this.startRefreshTokenTimer();
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token

    const refresh = localStorage.getItem('refresh');
    if (refresh) {
      const authToken = this.jwtHelper.decodeToken(refresh);

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(authToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - 60 * 1000;
      this.refreshTokenTimeout = setTimeout(
        () => {
          this.refreshToken();

          Swal.fire({
            title: "Fermeture session",
            icon: 'error',
            text: "Votre session a expirée, vous allez être déconnecté. Veuillez-vous reconnecter de nouveau.",
            // backdrop: false,
            showCancelButton: true,
            confirmButtonColor: "#006481",
            color: "#006481",
            iconColor: "#00533E",
            cancelButtonColor: "#d33",
            confirmButtonText: "Se reconnecter",
            allowEscapeKey: () => false,
            allowOutsideClick: () => false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.clear();

              this.router.navigate(['/login'])
            }
          });
        },
        timeout
      );
    }
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

}
