import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Categories } from 'src/models/categories.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private categoriesSubject: BehaviorSubject<Array<Categories>>;
  public categories$: Observable<Array<Categories>>;

  constructor(private http: HttpClient) {
    this.categoriesSubject = new BehaviorSubject<Array<Categories>>([]);
    this.categories$ = this.categoriesSubject.asObservable();
  }

  public get value(): Array<Categories> {
    return this.categoriesSubject.value;
  }

  update(categories: Array<Categories>){
    this.categoriesSubject.next(categories);
  }
  
  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}categories/`) as Observable<any>;
  }
}
