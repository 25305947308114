import { Component } from '@angular/core';

@Component({
  selector: 'app-labelisation-des-pme-pmi',
  templateUrl: './labelisation-des-pme-pmi.component.html',
  styleUrls: ['./labelisation-des-pme-pmi.component.scss']
})
export class LabelisationDesPmePmiComponent {

}
