import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Meta } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { catchError } from "rxjs/operators";
import { User } from "src/models/user.model";
import { AuthService } from "src/services/auth/auth.service";
import { UserService } from "src/services/user/user.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    form: FormGroup;
    loading: boolean;
    returnUrl: string;

    constructor(
        private fb: FormBuilder,
        private recaptchaV3Service: ReCaptchaV3Service,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private jwtHelperService: JwtHelperService,
        private userService: UserService,
        private meta: Meta
    ) { }

    ngOnInit(): void {
        this.returnUrl = this.route.snapshot.params.next;
        
        this.meta.addTag({ name: 'description', content: "Se connecter" });
        this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn, ' });
        this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });

        this.form = this.fb.group({
            username: this.fb.control(null, [Validators.required]),
            password: this.fb.control(null, [Validators.required]),
            recaptcha: this.fb.control(null, [Validators.required]),
        });

        if (this.authService.isAuthenticated()) {
            const auth = this.jwtHelperService.decodeToken();
            const returnUrl =
                auth.role === "supplier" || auth.role === "supplier_collegue"
                    ? "supplier/dashboard"
                    : "buyer/dashboard";

            window.location.replace(returnUrl);
        }
    }

    onSubmit() {
        if (this.form.invalid) {
            for (const control of Object.keys(this.form.controls)) {
                this.form.controls[control].markAsTouched();
            }
            return;
        }

        this.recaptchaV3Service
            .execute("importantAction")
            .subscribe((token: string) => {
                console.debug(`Token [${token}] generated`);
            });

        this.loading = true;
        this.authService.login(this.form.value).subscribe({
            next: (data: any) => {
                this.loading = false;
                const auth = this.jwtHelperService.decodeToken(data.access);

                // if (!auth.is_first_login) {
                    localStorage.setItem("access", data.access);
                    localStorage.setItem("refresh", data.refresh);
                    this.userService.currentUser().subscribe((user: User) => {
                        this.authService.update(user);

                        const returnUrl = this.route.snapshot.queryParams.next;
                        if (returnUrl != null) {
                            this.router.navigateByUrl(returnUrl, {});
                        } else {
                            console.log(auth)
                            const returnUrl =
                                auth.role === "supplier" ||
                                    auth.role === "supplier_collegue"
                                    ? "supplier/dashboard"
                                    : "buyer/dashboard";

                            this.router.navigateByUrl(returnUrl, {});
                        }
                    });
                // } else {
                //     localStorage.setItem('slug', auth.slug)
                //     localStorage.setItem('role', auth.role)
                //     this.router.navigate(['/change-password'])
                // }
            },
            error: (error) => {
                console.error(error);
                this.loading = false;
                Swal.fire({
                    title: "Authentification",
                    html: error.error.detail,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: '#006481',
                    color: '#006481',
                    iconColor: '#e40e20',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'FERMER',
                });
            },
        });
    }
}
