<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>LABELISATION DES PME/PMI</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>LABELISATION DES PME/PMI</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="faq-area pt-5">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-8">
                <div class="faq-head">
                    <!-- <h2>About Job</h2> -->
                    <p style="text-align: justify;">
                        La labellisation est un processus de reconnaissance des compétences des sous-traitants accompagnés par la Bourse de Sous-Traitance et de Partenariat du Cameroun à l’issue de son programme d’accompagnement.
                    </p>
                    <p style="text-align: justify;">
                        C’est une activité supplémentaire et complémentaire du programme classique d’accompagnement des sous-traitants du programme SPX UNIDO, qui comprend :
                    </p>
                </div>
                
                <div class="faq-item mt-5">
                    <ul class="accordion">
                        <li class="wow fadeInUp  " data-wow-delay=".3s">
                            <a>1. Profilage</a>
                            <p>Recensement et renseignement dans la base de données des informations générales d’identification, des qualifications techniques et spécifiques des PME / PMI.</p>
                        </li>

                        <li class="wow fadeInUp  " data-wow-delay=".4s">
                            <a>2. L’évaluation comparative (benchmarking)</a>
                            <p style="text-align: justify;">comparaison des performances et des pratiques opérationnelles d’un sous-traitant avec celles des meilleures références internationales.</p>
                        </li>

                        <li class="wow fadeInUp  " data-wow-delay=".5s">
                            <a>3. Le développement des compétences</a>
                            <p style="text-align: justify;">
                                L’approche de labellisation de la BSTP renforce le mécanisme d’accompagnement existant et comprend 2 étapes clés :
                                <br>
                                <strong>-</strong> La collecte des éléments de preuve de la compétence avérée du sous-traitant ;
                                <br>
                                <strong>-</strong> L’enquête de satisfaction des donneurs d’ordre.
                            </p>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-8">
                <div class="faq-head">
                    <!-- <h2>Web Development</h2> -->
                    <p style="text-align: justify;">L’approche de labellisation de la BSTP renforce le mécanisme d’accompagnement existant et comprend 2 étapes clés :
                    </p>
                    <ul>
                        <li>La collecte des éléments de preuve de la compétence avérée du sous-traitant ;</li>
                        <li>L’enquête de satisfaction des donneurs d’ordre.</li>
                    </ul>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        
                        <li class="wow fadeInUp  " data-wow-delay=".3s">
                            <a>Lablisation des sous-traitants</a> 
                            <p style="text-align: justify;">Chaque étape du programme d’accompagnement de la BSTP Cameroun est une sorte d’évaluation avec des objectifs de conformité et/ou de performance visés. Sur la base de la notation des différents critères du profiling, du benchmarking et du Supply chain management:</p>
                            

                            <div class="table-responsive px-4">
                                <table class="table table-sm nowrap">
                                    <thead>
                                        <tr>
                                            <th>N°</th>
                                            <th>LABEL</th>
                                            <th>NOTES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>1</th>
                                            <td>LABEL NIVEAU I</td>
                                            <td>100</td>
                                        </tr>
                                        <tr>
                                            <th>2</th>
                                            <td>LABEL NIVEAU II</td>
                                            <td>100</td>
                                        </tr>
                                        <tr>
                                            <th>3</th>
                                            <td>LABEL NIVEAU III</td>
                                            <td>100</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style="text-align: justify;">
                                <strong>NB :</strong> Les niveaux des Labels sont indépendants des uns des autres. Toutefois, les entreprises voulant obtenir un Label du niveau II ou du niveau III sont contraintes d’obtenir des Labels du niveau inférieur.
                            </p>
                        </li>

                        <li class="wow fadeInUp  " data-wow-delay=".4s">
                            <a> Eligibilité à la labellisation</a>
                            <p style="text-align: justify;">Sont éligibles à la labellisation, les sous-traitants qui ont suivi avec succès toutes les étapes du programme d’accompagnement de la BSTP ci-dessus. Le label est attribué si les détails à vérifier ont permis d’atteindre un score minimum de 80 points sur les critères définis.</p>
                        </li>

                        <li class="wow fadeInUp  " data-wow-delay=".5s">
                            <a>Périodicité des activités de labellisation et durée de validité du label</a>
                            <p style="text-align: justify;">Les activités de labellisation des sous-traitants de la BSTP sont planifiées et réalisées suivant la démarche standard de la mise en œuvre des missions de la BSTP. Les sous-traitants satisfaisants à l’issue de la labellisation reçoivent leur parchemin.</p>
                            <p style="text-align: justify;">Les Labels de compétence décernés par la BSTP aux différents sous-traitants sur la base de leur expertise de maitrise d’ouvrage dans des spécialités définies ont une durée de vie de 2 ans. Le renouvellement du Label suit le même parcours initial de la labellisation.</p>
                        </li>

                        <li class="wow fadeInUp  " data-wow-delay=".6s">
                            <a>Avantages des sous-traitants labellisés</a>
                            <p style="text-align: justify;">Les sous-traitants ayant réussis avec brio l’ensemble des étapes du programme d’accompagnement de la BSTP et la labellisation présentent une garantie raisonnable de la réalisation efficace des prestations sollicitées par un donneur d’ordre. Lors d’une opportunité de mise en relation avec ces derniers, la BSTP sélectionne les sous-traitants du domaine d’activité et de la spécialité sollicitée selon le niveau de label sollicité par le Donneur d’Ordres.</p>
                        </li>

                        <li class="wow fadeInUp  " data-wow-delay=".7s">
                            <a>L’élément d’entrée</a>
                            <p style="text-align: justify;">Le déclencheur de l’activité de labellisation est l’élaboration du programme annuel de labellisation réalisé par un Collège des Experts de la BSTP (constitué par le Directeur) et validé par le Directeur de la BSTP ; une sollicitation de l’entreprise sous-traitante ou de l’entreprise Donneur d’Ordres.</p>
                        </li>
                        
                        <li class="wow fadeInUp  " data-wow-delay=".7s">
                            <a>L’élément de sortie</a>
                            <p style="text-align: justify;">L’aboutissement de la présente procédure «labelliser les sous-traitants accompagnés par la BSTP» est la remise du parchemin à chaque sous-traitant ayant satisfait au quota des points exigible par niveau de label sollicité.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
