import { Component, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AppSetting } from 'src/models/app-setting';
import { AppSettingService } from 'src/services/app-setting/app-setting.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  
  constructor(public appSettingService: AppSettingService, private meta: Meta) { }

  ngOnInit(): void {
    this.meta.addTag({ name: 'description', content: "Termes et Conditions d'Utilisation" });
    this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn, ' });
    this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });
  }

}
