import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private meta: Meta) { }

  ngOnInit(): void {
    this.meta.addTag({ name: 'description', content: "Page d'accueil de la plateforme de la BSTP" });
    this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn, Contact BSTP' });
    this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });
  }

}
