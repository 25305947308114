import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Commerciales } from 'src/models/commerciales';

@Injectable({
  providedIn: 'root'
})
export class CommercialesService {

  constructor(private http: HttpClient) { }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}commerciales/`) as Observable<any>;
  }

  create(commerciales: Commerciales): Observable<any> {
    return this.http.post(`${environment.apiUrl}commerciales/`, commerciales) as Observable<any>;
  }

  update(slug: string, commerciales: Commerciales): Observable<any> {
    return this.http.put(`${environment.apiUrl}commerciales/${slug}/`, commerciales) as Observable<any>;
  }

  delete(slug: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}commerciales/${slug}/`) as Observable<any>;
  }

  getSupplierCommerciales(slug: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}commerciales/get_commerces_supplier/${slug}/`) as Observable<any>;
  }
}
