<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>ACHETEURS & PARTENAIRES</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>PARTENAIRES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="job-area job-area-two mt-5">
    <div class="container">
        <form class="search-form">
            <input type="email" class="form-control" [(ngModel)]="search" placeholder="Saisir le nom d'un acheteur ou un partenaire" name="search" autocomplete="off">
            <button class="btn subscribe-btn" type="submit"><i class="icofont-search" style="font-weight: bold;"></i></button>
        </form>
    </div>
    <!-- <div class="item">
        <ngx-skeleton-loader count="1" appearance="square" />
    </div> -->
    <div class="container mt-5" *ngIf="partenairesType">
        <div class="row" *ngFor="let partType of filteredPartenaires">
            <div class="col-lg-12 mb-3">
                <h2 class="display-5 text-danger"> {{ partType.name }}</h2>
                <hr>
            </div>
            <div class="col-lg-12 wow fadeInUp" *ngFor="let partenaire of partType.partenaires">
                <div class="row mb-5">
                    <div class="col-lg-2">
                        <div class="instructor-img">
                            <img [src]="partenaire.logo" alt="Images" />
                        </div>
                    </div>
        
                    <div class="col-lg-10">
                        <div class="instructor-content">
                            <h3>{{ partenaire.name }}</h3>
        
                            <p style="text-align: justify;" class="text-justify">
                                {{ partenaire.description }}
                            </p>
                            <!-- <a routerLink="/appointment" class="default-btn1" >Book Appointment</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-loading [loading]="loading" />