import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/services/payment/payment.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-first-step',
  templateUrl: './payment-first-step.component.html',
  styleUrls: ['./payment-first-step.component.scss']
})
export class PaymentFirstStepComponent implements OnInit {
  loading: boolean;

  constructor(private paymentService: PaymentService) { }

  ngOnInit(): void {}

  onPayment() {
    this.loading = true;
    this.paymentService.pay().subscribe({
      next: response => {
        this.loading = false;
        // console.log(response)
        localStorage.setItem('pay_id', response.pay_id)
        window.location.href = response.payment_url
      },
      error: error => {
        let message = error.error.detail || error.error.non_error_fields[0];
        this.loading = false;
        // console.log(message);

        Swal.fire({
          title: "Processus de Paiement",
          text: message,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#006481",
          color: "#006481",
          iconColor: "#e40e20",
          cancelButtonColor: "#d33",
          confirmButtonText: "Reesayer",
          cancelButtonText: "Fermer",
          allowOutsideClick: () => false,
        });
      }
    })
  }
}
