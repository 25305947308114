<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>FAQs</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>FAQs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-5">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow {{ i == 0 ? 'fadeInUp': '' }}" data-wow-delay=".5s" *ngFor="let faq of faqService.value; index as i">
                            <a>{{ faq.question }}</a>
                            <p style="text-align: justify;" [innerHTML]="faq.remark"></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>