import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/models/user.model';
import { environment } from 'src/environments/environment';
import { Company } from 'src/models/company.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject: BehaviorSubject<User>;
  public user$: Observable<User>;
  
  constructor(private http: HttpClient) { }

  create(data: User): Observable<User> {
    return this.http.post(`${environment.apiUrl}user/`, data, {
      headers: {
        'Authorization': environment.apiKey
      }
    }) as Observable<User>;
  }

  register(data: User): Observable<Company> {
    return this.http.post(`${environment.apiUrl}user/register/`, data) as Observable<Company>;
  }

  update(slug: string, user: User): Observable<User> {
    return this.http.put(`${environment.apiUrl}user/${slug}/`, user) as Observable<User>;
  }

  disable(): Observable<User> {
    return this.http.get(`${environment.apiUrl}user/disable/`) as Observable<User>
  }

  currentUser(): Observable<User> {
    return this.http.get(`${environment.apiUrl}user/current_user/`) as Observable<User>
  }

  resetPassword(slug: string, preload: any): Observable<User> {
    return this.http.put(`${environment.apiUrl}user/${slug}/reset_password/`, preload) as Observable<User>;
  }

  changePassword(preload: any, uidb64: string, token: string): Observable<User> {
    return this.http.put(`${environment.apiUrl}user/change_password/${uidb64}/${token}/`, preload) as Observable<User>;
  }

  getCollegues(slug: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}user/${slug}/get_collegues/`) as Observable<any>;
  }

  retrieve(slug: string): Observable<User> {
    return this.http.get(`${environment.apiUrl}user/${slug}/`) as Observable<User>;
  }

  createBuyerCollegue(payload): Observable<User> {
    return this.http.post(`${environment.apiUrl}user/create_buyer_collegue/`, payload) as Observable<User>;
  }

  createSupplierCollegue(payload): Observable<User> {
    return this.http.post(`${environment.apiUrl}user/create_supplier_collegue/`, payload) as Observable<User>;
  }

  getAllSupplierCollegues(): Observable<Array<User>> {
    return this.http.get(`${environment.apiUrl}user/get_all_supplier_collegues/`) as Observable<Array<User>>;
  }

  forgotPassword(email: string): Observable<string> {
    return this.http.get(`${environment.apiUrl}user/forgot_password/${email}/`) as Observable<string>;
  }
}
