<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>FEEDBACK</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>FEEDBACK</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="job-area job-area-two mt-5">
    <div class="container">
        <form enctype="multipart/form-data" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="post-job-item shadow mb-5">
                <h3>Formulaire de Retours Utilisateurs</h3>
                <hr />
                <p>
                    Merci de prendre quelques minutes pour nous donner vos retours sur nos services en remplissant un court Formulaire de Retours Clients. Nous menons cette enquête de retours pour juger les niveaux de satisfaction des clients de la qualité de nos services.
                </p>
                <p>
                    Nous vous remercions pour votre participation à ce Formulaire de Retours Utilisateurs et pour votre utilisation de nos services, et nous espérons être à la hauteur de vos attentes.
                </p>
                <form class="mt-5">
                    <div class="form-group row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8">
                            <div class="row">
                                <label for="" class="col text-center">Very Good</label>
                                <label for="" class="col text-center">Good</label>
                                <label for="" class="col text-center">Fair</label>
                                <label for="" class="col text-center">Poor</label>
                                <label for="" class="col text-center">Very Poor</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">Comment évaluez-vous votre expérience globale avec nos services?</label>
                      <div class="col-sm-8">
                        <div class="job-type-area">
                            <div class="row">
                                <div class="col text-center">
                                    <div class="form-check form-check-inline text-center">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input text-center" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="inputPassword" class="col-sm-4 col-form-label">Êtes vous satisfait(e) par la compréhension de vos besoins par nos offres?</label>
                      <div class="col-sm-8">
                        <div class="job-type-area">
                            <div class="row">
                                <div class="col text-center">
                                    <div class="form-check form-check-inline text-center">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input text-center" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="inputPassword" class="col-sm-4 col-form-label">Êtes vous satisfait(e) du support client?</label>
                      <div class="col-sm-8">
                        <div class="job-type-area">
                            <div class="row">
                                <div class="col text-center">
                                    <div class="form-check form-check-inline text-center">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input text-center" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8">
                            <div class="row">
                                <label for="" class="col text-center">Very Satisfied</label>
                                <label for="" class="col text-center">Satisfied</label>
                                <label for="" class="col text-center">Neutral</label>
                                <label for="" class="col text-center">Unsatisfied</label>
                                <label for="" class="col text-center">Very Unsatisfied</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                      <label for="inputPassword" class="col-sm-4 col-form-label">Êtes vous satisfait(e) avec les temps de préparation et de livraison de commande?</label>
                      <div class="col-sm-8">
                        <div class="job-type-area">
                            <div class="row">
                                <div class="col text-center">
                                    <div class="form-check form-check-inline text-center">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input text-center" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="form-group row">
                      <label for="inputPassword" class="col-sm-4 col-form-label">Comment trouvez-vous les prix?</label>
                      <div class="col-sm-8">
                        <div class="job-type-area">
                            <div class="row">
                                <div class="col text-center">
                                    <div class="form-check form-check-inline text-center">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input text-center" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                                <div class="col text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Recommanderiez-vous produit/service à d'autres personnes?</label>
                        <div class="col-sm-8">
                            <div class="job-type-area">
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" value="M" checked>
                                    <label class="form-check-label" for="male"> Très Certainement</label>
                                </div>
    
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    <label class="form-check-label" for="female"> Probablement</label>
                                </div>

                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    <label class="form-check-label" for="female"> Neutre</label>
                                </div>
                                
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    <label class="form-check-label" for="female"> Probablement Pas</label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" value="F">
                                    <label class="form-check-label" for="female"> Très Certainement Pas</label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4">Que devrions-nous améliorer, selon vous?</label>
                        <div class="col-sm-8">
                            <div class="NgxEditor__Wrapper">
                                <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                                <ngx-editor formControlName="description" cols="30" [editor]="editor" [ngModel]="html" [disabled]="false" [placeholder]="'Saisissez ici...'"></ngx-editor>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8">
                            <button class="btn btn-green">SOUMETTRE VOTRE RETOUR</button>
                        </div>
                    </div>
                </form>
            </div>
        </form>
    </div>
</section>
