import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-guides',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.scss']
})
export class GuidesComponent implements OnInit {

  constructor(private meta: Meta) {}

  ngOnInit(): void {
    this.meta.addTag({ name: 'description', content: "Guides d'Utilisation" });
    this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn, ' });
    this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });
  }

}
