import { Component } from '@angular/core';

@Component({
  selector: 'app-programme-d-amelioration-de-contenu',
  templateUrl: './programme-d-amelioration-de-contenu.component.html',
  styleUrls: ['./programme-d-amelioration-de-contenu.component.scss']
})
export class ProgrammeDAmeliorationDeContenuComponent {

}
