<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>MOT DE PASSE OUBLIÉ</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>MOT DE PASSE OUBLIÉ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-area pt-5">
    <div class="container">
        <div class="alert-message alert-message-info">
            <h4>Mot de passe oublié</h4>
            <p>
                L'accès au détail de cet appel d'offres nécessite une autorisation spéciale de connexion à votre compte fournisseur. Veuillez
                saisir votre authentifications nécessaires pour vous autoriser à voir le détail de cet appel d'offres.
            </p>
            <p>
                Ou si vous êtes à votre fois de consulter notre, nous vous recommandons de bien vouloir vous enregistrer afin de bénéficier aux opportunités d'appels d'offres
            </p>
            <p>
                Merci pour la compréhension.
            </p>
        </div>
    </div>
    <div class="container mt-5">
        <div class="login-page">
            <div class="form">
                <form
                    class="login-form"
                    [formGroup]="form"
                    (ngSubmit)="onSubmit()"
                >
                    <div class="form-group col-lg-4 text-start">
                        <label for="email">Adresse électronique <span class="text-danger">(OBLIGATOIRE)</span></label>
                        <input
                            class="form-control"
                            type="email"
                            formControlName="email"
                            placeholder="Saisissez votre email"
                        />
                    </div>

                    <re-captcha
                        class="w-100"
                        id="recaptcha"
                        formControlName="recaptcha"
                    ></re-captcha>

                    <button type="submit" class="mt-5 btn btn-green w-25" [disabled]="form.invalid || loading">
                        ENVOYER <i *ngIf="loading" class="icofont-spinner-alt-1 icofont-spin"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
