import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Submission } from 'src/models/submission';

@Injectable({
  providedIn: 'root'
})
export class SubmissionService {

  constructor(private http: HttpClient) { }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}submit/`) as Observable<any>;
  }

  create(submission: Submission): Observable<Submission> {
    return this.http.post(`${environment.apiUrl}submit/`, submission) as Observable<Submission>;
  }

  update(pk: number, submission: Submission): Observable<Submission> {
    return this.http.put(`${environment.apiUrl}submit/${pk}/`, submission) as Observable<Submission>
  }

  getBuyerSubmissions(): Observable<any> {
    return this.http.get(`${environment.apiUrl}submit/get_buyer_submissions/`) as Observable<any>;
  }
  
  getSupplierSubmissions(): Observable<any> {
    return this.http.get(`${environment.apiUrl}submit/get_supplier_submissions/`) as Observable<any>;
  }

  getAllSubmitters(slug: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}submit/${slug}/get_all_submitters/`) as Observable<any>;
  }

  companySubmit(slug: string): Observable<Submission> {
    return this.http.get(`${environment.apiUrl}submit/company_submited/${slug}/`) as Observable<Submission>
  }

  retrieve(slug: string): Observable<Submission> {
    return this.http.get(`${environment.apiUrl}submit/${slug}/`) as Observable<Submission>
  }

  subscribe(payload: any): Observable<Submission> {
    return this.http.post(`${environment.apiUrl}submit/subscribe/`, payload) as Observable<Submission>;
  }
}
