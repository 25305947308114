import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Editor } from 'ngx-editor';
import { AuthService } from 'src/services/auth/auth.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {
  form: FormGroup;

  editor: Editor;
  html = "";

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  ngOnInit(): void {
      this.editor = new Editor();
  }

  ngOnDestroy(): void {
      this.editor.destroy();
  }

  onSubmit() {}
}
