import { Component } from '@angular/core';

@Component({
  selector: 'app-mail-already-confirmed',
  standalone: true,
  imports: [],
  templateUrl: './mail-already-confirmed.component.html',
  styleUrls: ['./mail-already-confirmed.component.scss']
})
export class MailAlreadyConfirmedComponent {

}
