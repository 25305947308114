import { Component } from '@angular/core';

@Component({
  selector: 'app-mail-unconfirmed',
  standalone: true,
  imports: [],
  templateUrl: './mail-unconfirmed.component.html',
  styleUrls: ['./mail-unconfirmed.component.scss']
})
export class MailUnconfirmedComponent {

}
