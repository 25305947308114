import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppSettingService } from "src/services/app-setting/app-setting.service";
import { AuthService } from "src/services/auth/auth.service";
import { CategoriesService } from "src/services/categories/categories.service";
import { CommmuneService } from "src/services/commune/commmune.service";
import { CountriesService } from "src/services/countries/countries.service";
import { FaqService } from "src/services/faq/faq.service";
import { PartenaireTypeService } from "src/services/partenaire-type/partenaire-type.service";
import { PrefectureService } from "src/services/prefecture/prefecture.service";
import { RegionService } from "src/services/region/region.service";
import { StaffService } from "src/services/staff/staff.service";

export function appInitializer(
    authService: AuthService,
    appSettingService: AppSettingService,
    prefectureService: PrefectureService,
    regionService: RegionService,
    communeService: CommmuneService,
    countryService: CountriesService,
    categoryService: CategoriesService,
    staffService: StaffService,
    faqService: FaqService,
    partenaireTypeService: PartenaireTypeService
) {
    return () =>
        new Promise((resolve: any) => {
            // authService.refreshToken();
            authService.user$.subscribe().add(resolve());
            appSettingService.appSetting$.subscribe().add(resolve());
            prefectureService.prefectures$.subscribe().add(resolve());
            regionService.regions$.subscribe().add(resolve());
            communeService.communes$.subscribe().add(resolve());
            countryService.countries$.subscribe().add(resolve());
            categoryService.categories$.subscribe().add(resolve());
            staffService.staffs$.subscribe().add(resolve());
            faqService.faqs$.subscribe().add(resolve());
            partenaireTypeService.partenaires$.subscribe().add(resolve());
        });
}
