<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>TERMES ET CONDITIONS D'UTILISATION</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>TERMES ET CONDITIONS D'UTILISATION</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-5">
    <div class="container">
        <div class="privacy-item text-justify" style="font-size: 18px; text-align: justify;" [innerHTML]="appSettingService.value.terms_and_conditions"></div>
    </div>
</section>