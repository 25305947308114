import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Staff } from 'src/models/staff.model';
import { StaffService } from 'src/services/staff/staff.service';

@Component({
  selector: 'app-bstp-team',
  templateUrl: './bstp-team.component.html',
  styleUrls: ['./bstp-team.component.scss']
})
export class BstpTeamComponent implements OnInit {
  staffs: Array<Staff>;
  search: string;

  constructor(public staffService: StaffService, private meta: Meta) {}

  ngOnInit(): void {
    this.meta.addTag({ name: 'description', content: "Equipe BSTP" });
    this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn team, Team BSTP, Equipe BSTP' });
    this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });
  }

  get filteredTeam() {
    if (this.search === null || this.search === undefined || this.search === '') {
        return this.staffService.value;
    }
    
    return this.staffService.value.filter(request =>
      request.full_name.toLowerCase().includes(this.search.toLowerCase())
    );
  }

}
