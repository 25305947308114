import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Faq } from 'src/models/faq.model';


@Injectable({
  providedIn: 'root'
})
export class FaqService {
  private faqsSubject: BehaviorSubject<Array<Faq>>;
  public faqs$: Observable<Array<Faq>>;

  constructor(private http: HttpClient) {
    this.faqsSubject = new BehaviorSubject<Array<Faq>>([]);
    this.faqs$ = this.faqsSubject.asObservable();
  }

  public get value(): Array<Faq> {
    return this.faqsSubject.value;
  }

  update(faqs: Array<Faq>){
    this.faqsSubject.next(faqs);
  }
  
  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}faq/`) as Observable<any>;
  }
}
