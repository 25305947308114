import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Services } from 'src/models/services';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private http: HttpClient) { }

  list(): Observable<Array<Services>> {
    return this.http.get(`${environment.apiUrl}services/`) as Observable<Array<Services>>;
  }
}
