import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private jwtHelper: JwtHelperService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('token/') !== -1) {
      return next.handle(request)
    }

    if (request.url.indexOf('refresh/') !== -1) {
      return next.handle(request)
    }

    if (request.url.indexOf('verify/') !== -1) {
      return next.handle(request)
    }

    if (request.url.indexOf('blacklist/') !== -1) {
      return next.handle(request)
    }
    
    request = request.clone({
      withCredentials: true,
    });
    return next.handle(this.addHeader(request));
  }

  addHeader(request: HttpRequest<unknown>) {
    const token = this.jwtHelper.tokenGetter()
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: token != null ? 'JWT ' + token : environment.apiKey,
        }
      })
    }
    return request.clone({})
  }
}


// 'Access-Control-Allow-Origin': '*',
// 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
// 'Access-Control-Allow-Credentials': 'true',
// 'Content-Type': 'application/json',
// 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
