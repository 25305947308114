import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Commune } from 'src/models/commune';

@Injectable({
  providedIn: 'root'
})
export class CommmuneService {
  private communesSubject: BehaviorSubject<Array<Commune>>;
  public communes$: Observable<Array<Commune>>;

  constructor(private http: HttpClient) {
    this.communesSubject = new BehaviorSubject<Array<Commune>>([]);
    this.communes$ = this.communesSubject.asObservable();
  }

  public get value(): Array<Commune> {
    return this.communesSubject.value;
  }

  update(communes: Array<Commune>){
    this.communesSubject.next(communes);
  }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}communes/`) as Observable<any>;
  }


  getCommunesByPrefecture(pk: number) {
    return this.http.post(`${environment.apiUrl}communes/get_communes_by_pref/`, {prefecture: pk}) as Observable<any>;
  }
}
