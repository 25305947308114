import { Component, Input, OnInit } from '@angular/core';
import { AppSettingService } from 'src/services/app-setting/app-setting.service';
import { AuthService } from 'src/services/auth/auth.service';

@Component({
  selector: 'app-supplier-navbar',
  templateUrl: './supplier-navbar.component.html',
  styleUrls: ['./supplier-navbar.component.scss']
})
export class SupplierNavbarComponent {

  constructor(public authService: AuthService, public appSettingService: AppSettingService) {}
}
