import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Company } from "src/models/company.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class CompanyService {
    constructor(private http: HttpClient) {}

    list(): Observable<any> {
        return this.http.get(`/api/company/`) as Observable<any>;
    }

    update(slug: string, data: object): Observable<Company> {
        return this.http.patch(`/api/company/${slug}/`, data) as Observable<Company>;
    }

    create(data: object): Observable<Company> {
        return this.http.post(`/api/company/`, data) as Observable<Company>;
    }

    delete(slug: string): Observable<Company> {
        return this.http.delete(`/api/company/${slug}/`) as Observable<Company>;
    }

    supplierCompanies(): Observable<any> {
        return this.http.get(`/api/company/supplier_companies/`) as Observable<any>;
    }

    get(slug: string): Observable<any> {
        return this.http.get(`/api/company/${slug}/`) as Observable<Company>;
    }
}
