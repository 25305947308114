import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Payment } from 'src/models/payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  pay(): Observable<any> {
    return this.http.get(`${environment.apiUrl}payment/pay/`) as Observable<any>;
  }
  
  stepPay(slug: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}payment/step_pay/${slug}/`) as Observable<any>;
  }
  
  verified(pay_id: string, company_slug): Observable<any> {
    return this.http.get(`${environment.apiUrl}payment/verified/${pay_id}/${company_slug}/`) as Observable<any>;
  }

  list(slug: string): Observable<Array<Payment>> {
    return this.http.get(`${environment.apiUrl}payment/?company__slug=${slug}`) as Observable<Array<Payment>>;
  }

}
