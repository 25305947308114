<header class="bg-light" *ngIf="appSettingService.value != null">
    <div class="navbar-area fixed-top p-0">
        <div class="mobile-nav">
            <a routerLink="" class="logo"
                ><img
                    [src]="appSettingService.value.footer_logo"
                    style="width: 200px;"
                    alt="Logo"
            /></a>
        </div>

        <div class="main-nav p-0">
            <div class="flag"></div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-2 m-auto d-none d-lg-block text-start">
                        <img
                            [src]="appSettingService.value.logo"
                            width="300"
                            alt="Logo"
                            class="logo"
                        />
                    </div>
                    <div class="col-md-12 col-lg-10">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav
                                    class="navbar navbar-expand-md navbar-light"
                                >
                                    <ul class="navbar-nav d-flex bd-highlight">
                                        <li
                                            class="nav-item p-2 flex-fill bg-highlight"
                                        >
                                            <h6>
                                                <i class="icofont-location-pin text-default"></i>
                                                <span
                                                    ><strong>
                                                        Guinée - Conakry </strong
                                                    ><br />{{
                                                        appSettingService.value
                                                            .address
                                                    }}</span
                                                >
                                            </h6>
                                        </li>
                                        <li
                                            class="nav-item p-2 flex-fill bd-highlight"
                                        >
                                            <h6>
                                                <i class="icofont-phone text-default"></i>
                                                <span
                                                    ><strong>
                                                        +224 {{
                                                            appSettingService
                                                                .value.phone_number2
                                                        }} </strong
                                                    ><br />Appelez-nous</span
                                                >
                                            </h6>
                                        </li>
                                        <li
                                            class="nav-item p-2 flex-fill bd-highlight"
                                        >
                                            <h6>
                                                <i
                                                    class="icofont-email text-default"
                                                ></i>
                                                <span
                                                    ><strong>
                                                        {{
                                                            appSettingService
                                                                .value.email
                                                        }} </strong
                                                    ><br />Contactez-nous</span
                                                >
                                            </h6>
                                        </li>
                                        
                                        <li
                                            class="nav-item p-2 flex-fill bd-highlight"
                                        >
                                            <h6>
                                                <i
                                                    class="icofont-facebook text-default"
                                                ></i>
                                                
                                            </h6>
                                        </li>
                                        
                                        <li
                                            class="nav-item p-2 flex-fill bd-highlight"
                                        >
                                            <h6>
                                                <i
                                                    class="icofont-youtube-play text-default"
                                                ></i>
                                                
                                            </h6>
                                        </li>
                                        
                                        <li
                                            class="nav-item p-2 flex-fill bd-highlight"
                                        >
                                            <h6>
                                                <i
                                                    class="icofont-twitter text-default"
                                                ></i>
                                                
                                            </h6>
                                        </li>
                                        <li
                                            class="nav-item p-2 flex-fill bd-highlight"
                                        >
                                            <h6>
                                                <i
                                                    class="icofont-instagram text-default"
                                                ></i>
                                                
                                            </h6>
                                        </li>

                                        <li
                                            style="cursor: pointer;" (click)="authService.logout()"
                                            class="nav-item p-2 flex-fill bd-highlight"
                                        >
                                            <h6>
                                                
                                                <span
                                                    class="mt-2"
                                                    ><strong>Deconnexion </strong
                                                    ></span
                                                >
                                            </h6>
                                        </li>
                                        
                                        
                                        
                                    </ul>
                                </nav>
                                <hr />
                            </div>
                            <div class="col-lg-12">
                                <nav class="navbar navbar-expand-md navbar-light">
                                    <!-- <a class="navbar-brand" routerLink="/"><img [src]="appSettingService.value.logo" width="200" alt="Logo"></a> -->
                                
                                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                        <ul class="navbar-nav">
                                
                                            <li class="nav-item"><a routerLink="/organisme/dashboard" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">TABLEAU DE BORD</a></li>
                                            
                                            
                                            <li class="nav-item"><a routerLink="/organisme/thematiques" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">THEMATIQUES</a></li>
                                            
                                            <li class="nav-item">
                                                <a href="#" class="nav-link dropdown-toggle">FORMATIONS<i class="icofont-simple-down"></i></a>
                                
                                                <ul class="dropdown-menu">
                                                    <li class="nav-item"><a routerLink="/organisme/formation/add" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">NOUVELLE FORMATION</a></li>
                                                    <li class="nav-item"><a routerLink="/organisme/formation/list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">VOIR LES FORMATIONS</a></li>
                                                </ul>
                                            </li>
                                            
                                            <li class="nav-item">
                                                <a href="#" class="nav-link dropdown-toggle">FINANCEMENT<i class="icofont-simple-down"></i></a>
                                
                                                <ul class="dropdown-menu">
                                                    <li class="nav-item"><a routerLink="/organisme/financement-add" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">NOUVELLE FORMATION</a></li>
                                                    <li class="nav-item"><a routerLink="/organisme/financements" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">VOIR LES FORMATIONS</a></li>
                                                </ul>
                                            </li>
                                            
                                        </ul>
                                        <ul class="navbar-nav navbar-right text-end">
                                
                                            <li class="nav-item">
                                                <a href="#" class="nav-link dropdown-toggle sign-up-btn">MON COMPTE<i class="icofont-simple-down"></i></a>
                                
                                                <ul class="dropdown-menu">
                                                    <li class="nav-item"><a routerLink="/organisme/profile" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{ authService.value.first_name }} {{ authService.value.last_name }}</a></li>
                                                    <li class="nav-item"><a routerLink="/organisme/company-settings" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link" style="cursor: pointer;">INFORMATION SUR L'ENTREPRISE</a></li>
                                                    <li class="nav-item"><a (click)="authService.logout()" class="nav-link" style="cursor: pointer;">DÉCONNEXION</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>