import { Component, OnInit } from "@angular/core";
import {
    FormArray,
    FormGroup,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { Company } from "src/models/company.model";
import { Requests } from "src/models/requests";
import { AuthService } from "src/services/auth/auth.service";
import { CompanyService } from "src/services/company/company.service";
import { RequestsService } from "src/services/requests/requests.service";
import { SubmissionService } from "src/services/submission/submission.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-request-detail",
    templateUrl: "./request-detail.component.html",
    styleUrls: ["./request-detail.component.scss"],
})
export class RequestDetailComponent implements OnInit {
    request: Requests;
    loading: boolean;
    form: UntypedFormGroup;
    file: File | null;
    companies: Array<Company>;

    constructor(
        private requestService: RequestsService,
        private route: ActivatedRoute,
        private titleService: Title,
        private modalService: NgbModal,
        config: NgbModalConfig,
        private fb: UntypedFormBuilder,
        private companyService: CompanyService,
        private authService: AuthService,
        private submissionService: SubmissionService,
    ) {
        config.backdrop = "static";
        config.keyboard = false;
    }

    initForm() {
        this.form = this.fb.group({
            request: this.fb.control(null, []),
            company: this.fb.control(null, []),
            documents: this.fb.array(
                [this.newDocument()],
                [Validators.required]
            ),
        });
    }

    get documents(): FormArray {
        return this.form.get("documents") as FormArray;
    }

    newDocument() {
        return this.fb.group({
            name: this.fb.control(null, [Validators.required]),
            document: this.fb.control(null, [Validators.required]),
        });
    }

    addDocument() {
        this.documents.push(this.newDocument());
    }

    removeDocument(i: number) {
        this.documents.removeAt(i);
    }

    ngOnInit(): void {
        this.initForm();
        const slug = this.route.snapshot.params.slug;
        this.loading = true;
        this.requestService.get(slug).subscribe({
            next: (data: Requests) => {
                this.request = data;
                this.titleService.setTitle(this.request.name.toUpperCase());
            },
            error: (error) => (this.loading = false),
            complete: () => (this.loading = false),
        });

        this.companyService
            .supplierCompanies()
            .subscribe((data: any) => this.companies = data);
    }

    open(content) {
        this.modalService.open(content, {
            ariaLabelledBy: "modal-basic-title",
            size: "xl",
            centered: true,
            scrollable: true,
        });
    }

    onFileChange(filename: string, index: number, event: any) {
        if (event.target.files && event.target.files.length) {
            const reader = new FileReader();
            this.file = (event.target as HTMLInputElement).files[0];
            console.log(this.file);
            reader.readAsDataURL(this.file);
            reader.onload = () => this.documents.at(index).get(filename).setValue(reader.result);
        }
    }

    onSubmit() {
        console.log(this.form);
        this.form.get('request').setValue(this.request.id)
        Swal.fire({
            title: "Soumission",
            text: "Voulez-vous soumissionner cette entreprise à cette offre ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
        }).then((result) => {
            if (result.isConfirmed) {
                this.loading = true;
                this.submissionService.subscribe(this.form.value).subscribe({
                    next: (response: any) => {
                        this.loading = false;
                        this.modalService.dismissAll()
                        this.initForm();
                        Swal.fire(
                            "Succès",
                            "Votre demande de soumission a été effectuée",
                            'success'
                        );
                    },
                    error: (error) => {
                        this.loading = false;

                        let message =
                            error.error.detail ||
                            error.error.non_field_errors[0];

                        Swal.fire("Erreur!", message, "error");
                    },
                });
            }
            this.loading = false;
        });
    }
}
