import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Prefecture } from 'src/models/prefecture';

@Injectable({
  providedIn: 'root'
})
export class PrefectureService {
  private prefecturesSubject: BehaviorSubject<Array<Prefecture>>;
  public prefectures$: Observable<Array<Prefecture>>;

  constructor(private http: HttpClient) {
    this.prefecturesSubject = new BehaviorSubject<Array<Prefecture>>([]);
    this.prefectures$ = this.prefecturesSubject.asObservable();
  }

  public get value(): Array<Prefecture> {
    return this.prefecturesSubject.value;
  }

  update(prefectures: Array<Prefecture>){
    this.prefecturesSubject.next(prefectures);
  }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}prefectures/`) as Observable<any>;
  }

  getPrefecturesByRegion(pk: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}prefectures/get_prefectures_by_re/`, {region: pk}) as Observable<any>;
  }
}
