import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Article } from 'src/models/article';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) { }

  list(): Observable<Array<Article>> {
    return this.http.get(`${environment.apiUrl}article/`) as Observable<Array<Article>>;
  }

  limit(): Observable<Array<Article>> {
    return this.http.get(`${environment.apiUrl}article/limit/`) as Observable<Array<Article>>;
  }

  retrieve(slug: string): Observable<Article> {
    return this.http.get(`${environment.apiUrl}article/${slug}/`) as Observable<Article>;
  }
}
