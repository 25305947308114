<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>PROGRAMME D'AMELIORATION CONTINU</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>PROGRAMME D'AMELIORATION CONTINU</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="faq-area pt-5">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-8">
                <div class="faq-head">
                    <p>
                        Activez votre performance grâce à un programme d’amélioration continu conçu pour les PME sous-traitantes ayant un projet de création d’emplois. Engagez-vous dans ce processus complet et misez sur le développement des compétences de vos ressources humaines pour accroître votre compétitivité.
                    </p>
                    <h4 class="text-green">Comment ça fonctionne ?</h4>
                </div>
                
                <div class="faq-item mt-5">
                    <ul class="accordion">
                        <li class="wow fadeInUp " data-wow-delay=".3s">
                            <a>1. Profilage - Évaluation complète et objective</a>
                            <p style="text-align: justify;">
                                <strong>-</strong> Un de nos conseillers se rend dans votre entreprise (1 journée) <br>
                                &nbsp;&nbsp;&nbsp;1. Visite de l’usine et des bureaux <br>
                                &nbsp;&nbsp;&nbsp;2. Discussion avec les employés et les dirigeants <br>
                                &nbsp;&nbsp;&nbsp;3. Questionnaire portant sur tous les aspects de l’entreprise : <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Approvisionnements <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Fabrication <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Gestion de la qualité <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Gestion des ressources humaines <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. Gestion des ressources financières <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. Organisation de l’entreprise <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. R&D et ingénierie <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8. Ventes et marketing <br>
                                <strong>-</strong> Nous analysons les résultats de l’évaluation et les comparons avec ceux d’entreprises semblables <br>
                                <strong>-</strong> Nous vous transmettons un rapport complet : <br>
                                &nbsp;&nbsp;&nbsp;1. Présentation détaillée de vos forces et des opportunités d’amélioration selon les <strong>meilleures pratiques d’affaires</strong> <br>
                                &nbsp;&nbsp;&nbsp;2. Classement de vos résultats sur une échelle situant les résultats obtenus par des entreprises semblables <strong>(benchmarking)</strong> <br>
                                &nbsp;&nbsp;&nbsp;3. Suggestion de priorités d’action à mettre en place pour améliorer significativement la performance de votre entreprise
                            </p>
                        </li>

                        <li class="wow fadeInUp " data-wow-delay=".4s">
                            <a>2. Établissement des priorités d’action</a>
                            <p>
                                <strong>-</strong> Discussion avec les dirigeants sur les recommandations du rapport d’évaluation <br>
                                <strong>-</strong> Établissement des priorités d’action en fonction de vos objectifs et des ressources disponibles <br>
                                <strong>-</strong> Identification des projets d’amélioration priorisés et des résultats attendus
                            </p>
                        </li>
                        <li class="wow fadeInUp " data-wow-delay=".4s">
                            <a>3. Formation-accompagnement de vos employés</a>
                            <p>
                                <strong>-</strong> Choix des ressources externes compétentes parmi les suggestions soumises par BSTP-GN et vos propres contacts <br>
                                <strong>-</strong> Activités de formation/accompagnement de vos employés pour implanter les projets d’améliorations choisis
                            </p>
                        </li>

                        <li class="wow fadeInUp " data-wow-delay=".5s">
                            <a>4. Suivi des résultats</a>
                            <p>
                                <strong>-</strong> Suivis réguliers de l’avancement des projets avec votre conseiller BSTP-GN <br>
                                <strong>-</strong> Possibilité de suivi pour pousser plus loin l’amélioration (réservé aux membres)
                            </p>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
