<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>GUIDE UTILISATEUR</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>GUIDE UTILISATEUR</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="job-area job-area-two pt-5 pb-170">
    <div class="container">
        <!-- <img src="./assets/img/how-it-works-supplier-fr.png" style="width: 100%;" alt=""> -->
        <div class="row wow fadeInUp">
            <div class="col-lg-12 mb-3">
                <h2 class="display-5 text-danger animate__animated animate__bounceInLeft wow" data-wow-delay=".5s">Étape 1</h2>
                <hr />
            </div>
            <div class="col-lg-12">
                <div class="row mb-5">
                    <div class="col-lg-2 animate__animated animate__bounceInLeft wow" data-wow-delay="1s">
                        <div class="instructor-img">
                            <img src="./assets/guides/01.png" height="150" alt="Images" />
                        </div>
                    </div>

                    <div class="col-lg-10">
                        <div class="instructor-content">
                            <h3 class="animate__animated animate__bounceInRight wow" data-wow-delay="1s">Complétez vos Coordonnées</h3>

                            <p style="text-align: justify;" class="text-justify animate__animated animate__bounceInRight wow" data-wow-delay="1.5s">
                                Nous vous invitons à créer votre profil
                                fournisseur afin de compléter vos informations
                                et de définir un mot de passe sécurisé pour
                                accéder à votre compte. Votre profil fournisseur
                                vous permettra de gérer vos informations, de
                                soumettre des offres, de suivre vos transactions
                                et de rester informé des opportunités
                                commerciales pertinentes pour votre entreprise.
                                Une fois votre profil créé et votre mot de passe
                                défini, vous pourrez accéder à votre compte à
                                tout moment en toute sécurité.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row wow fadeInUp">
            <div class="col-lg-12 mb-3">
                <h2 class="display-5 text-danger animate__animated animate__bounceInLeft wow" data-wow-delay=".5s">Étape 2</h2>
                <hr />
            </div>
            <div class="col-lg-12">
                <div class="row mb-5">
                    <div class="col-lg-2 animate__animated animate__bounceInLeft wow" data-wow-delay="1s">
                        <div class="instructor-img">
                            <img src="./assets/guides/03.png" height="150" alt="Images" />
                        </div>
                    </div>

                    <div class="col-lg-10">
                        <div class="instructor-content">
                            <h3 class="animate__animated animate__bounceInRight wow" data-wow-delay="1s">Créez votre Page d'Entreprise</h3>

                            <p style="text-align: justify;" class="text-justify animate__animated animate__bounceInRight wow" data-wow-delay="1.5s">
                                Nous vous encourageons à compléter le profil de
                                votre entreprise afin d'augmenter sa visibilité
                                et son attractivité auprès des clients
                                potentiels. En fournissant des informations
                                détaillées et pertinentes sur votre entreprise,
                                vous augmenterez vos chances d'être remarqué et
                                contacté par des clients intéressés par vos
                                produits ou services. Prenez quelques instants
                                pour compléter votre profil et mettre en avant
                                les points forts de votre entreprise.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row wow fadeInUp">
            <div class="col-lg-12 mb-3">
                <h2 class="display-5 text-danger animate__animated animate__bounceInLeft wow" data-wow-delay=".5s">Étape 3</h2>
                <hr />
            </div>
            <div class="col-lg-12">
                <div class="row mb-5">
                    <div class="col-lg-2 animate__animated animate__bounceInLeft wow" data-wow-delay="1s">
                        <div class="instructor-img">
                            <img src="./assets/guides/02.png" height="150" alt="Images" />
                        </div>
                    </div>

                    <div class="col-lg-10">
                        <div class="instructor-content">
                            <h3 class="animate__animated animate__bounceInRight wow" data-wow-delay="1s">Confirmez votre Adresse Email</h3>

                            <p style="text-align: justify;" class="text-justify animate__animated animate__bounceInRight wow" data-wow-delay="1.5s">
                                Après avoir créé votre profil, veuillez
                                confirmer votre adresse email en cliquant sur le
                                lien de confirmation que nous vous avons envoyé.
                                Une fois votre adresse email confirmée, vous
                                serez inscrit pour recevoir nos offres
                                exclusives et opportunités commerciales. Restez
                                informé des dernières nouvelles et des
                                possibilités de collaboration en confirmant
                                votre profil dès maintenant.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row wow fadeInUp">
            <div class="col-lg-12 mb-3">
                <h2 class="display-5 text-danger animate__animated animate__bounceInLeft wow" data-wow-delay=".5s">Étape 4</h2>
                <hr />
            </div>
            <div class="col-lg-12">
                <div class="row mb-5">
                    <div class="col-lg-2 animate__animated animate__bounceInLeft wow" data-wow-delay="1s">
                        <div class="instructor-img">
                            <img src="./assets/guides/04.png" height="150" alt="Images" />
                        </div>
                    </div>

                    <div class="col-lg-10">
                        <div class="instructor-content">
                            <h3 class="animate__animated animate__bounceInRight wow" data-wow-delay="1s">Procédez au Paiement</h3>

                            <p style="text-align: justify;" class="text-justify animate__animated animate__bounceInRight wow" data-wow-delay="1.5s">
                                Une fois que nous aurons reçu votre paiement,
                                votre entreprise sera répertoriée dans notre
                                base de données exclusive de la Bourse des
                                Sous-traitances et de Partenariats. Cette
                                inscription vous permettra d'accéder à un réseau
                                de partenaires potentiels et d'explorer de
                                nouvelles opportunités commerciales. Ne manquez
                                pas cette occasion de promouvoir votre
                                entreprise et de développer votre activité en
                                rejoignant notre plateforme dès aujourd'hui.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row wow fadeInUp">
            <div class="col-lg-12 mb-3">
                <h2 class="display-5 text-danger animate__animated animate__bounceInLeft wow" data-wow-delay=".5s">Étape 5</h2>
                <hr />
            </div>
            <div class="col-lg-12">
                <div class="row mb-5">
                    <div class="col-lg-2 animate__animated animate__bounceInLeft wow" data-wow-delay="1s">
                        <div class="instructor-img">
                            <img src="./assets/guides/05.png" height="150" alt="Images" />
                        </div>
                    </div>

                    <div class="col-lg-10">
                        <div class="instructor-content">
                            <h3 class="animate__animated animate__bounceInRight wow" data-wow-delay="1s">Beneficiez des Services de la BSTP</h3>

                            <p style="text-align: justify;" class="text-justify animate__animated animate__bounceInRight wow" data-wow-delay="1.5s">
                                Accédez à toutes les opportunités d'appels
                                d'offres en vous inscrivant à notre service de
                                notifications. Vous recevrez une notification à
                                chaque fois qu'un nouvel appel d'offre est
                                posté, vous permettant ainsi de rester informé
                                des dernières opportunités commerciales
                                pertinentes pour votre entreprise. Ne manquez
                                aucune chance de soumettre une offre en vous
                                inscrivant dès maintenant pour recevoir nos
                                notifications en temps réel.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row wow fadeInUp">
            <div class="col-lg-12 mb-3">
                <h2 class="display-5 text-danger animate__animated animate__bounceInLeft wow" data-wow-delay=".5s">Étape 6</h2>
                <hr />
            </div>
            <div class="col-lg-12">
                <div class="row mb-5">
                    <div class="col-lg-2 animate__animated animate__bounceInLeft wow" data-wow-delay="1s">
                        <div class="instructor-img">
                            <img src="./assets/guides/06.png" height="150" alt="Images" />
                        </div>
                    </div>

                    <div class="col-lg-10">
                        <div class="instructor-content">
                            <h3 class="animate__animated animate__bounceInRight wow" data-wow-delay="1s">
                                Optimisez la Qualité de vos Produits et Services
                            </h3>

                            <p style="text-align: justify;" class="text-justify animate__animated animate__bounceInRight wow" data-wow-delay="1.5s">
                                Découvrez notre bibliothèque de guides
                                pratiques, conçue pour vous aider à approfondir
                                vos connaissances et à améliorer vos compétences
                                dans divers domaines. En plus de nos guides,
                                nous vous proposons des formations de
                                renforcement de compétences et de capacités
                                animées par des experts de l'industrie. Profitez
                                de cette opportunité pour acquérir de nouvelles
                                compétences et rester à jour avec les dernières
                                tendances de votre secteur.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center wow fadeInUp">
            <a routerLink="/create-account" class="btn btn-main"><i class="icofont-file-alt"></i> J'ADHÈRE MAINTENANT</a>
        </div>
    </div>
</section>
