import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SupplierRating } from 'src/models/supplier-rating';

@Injectable({
  providedIn: 'root'
})
export class SpecialGroupService {

  constructor(private http: HttpClient) { }

  create(payload: SupplierRating): Observable<SupplierRating> {
    return this.http.post(`${environment.apiUrl}supplier_rating/`, payload) as Observable<SupplierRating>;
  }

  
}
