import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AppSettingService } from "src/services/app-setting/app-setting.service";
import { AuthService } from "src/services/auth/auth.service";
import { Meta } from "@angular/platform-browser";
import { ArticleService } from "src/services/article/article.service";
import { Article } from "src/models/article";
import { RequestType } from "src/models/request-type";
import { RequestTypeService } from "src/services/request-type/request-type.service";
import * as d3 from "d3";
import { BaseChartComponent, ColorHelper } from "@swimlane/ngx-charts";
import { Services } from "src/models/services";
import { ServicesService } from "src/services/services/services.service";

import * as $ from 'jquery';

import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Decimation,
    Filler,
    Legend,
    registerables,
    Title,
    Tooltip,
} from "chart.js";
import { numberWithSpaces, startCounting } from "src/utils/utils";
import { AppSetting } from "src/models/app-setting";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    articles: Array<Article>;
    requestTypes: RequestType;
    services: Array<Services>;

    dims: any;
    xScale: any;
    yScale: any;
    xDomain: any;
    yDomain: any;
    colors: ColorHelper;
    colorScheme: any = "cool";
    @Input() view;
    @Input() results;

    count: number = 0;
    speed: number = 1; // 1 pour lent, peut être augmenté pour accélérer
    intervalId: any;
    target: number = 0;
    
    count1: number = 0;
    speed1: number = 1; // 1 pour lent, peut être augmenté pour accélérer
    intervalId1: any;
    target1: number = 0;

    constructor(
        private authService: AuthService,
        private router: Router,
        private jwtHelper: JwtHelperService,
        public appSettingService: AppSettingService,
        private meta: Meta,
        private articleService: ArticleService,
        private typeRequestService: RequestTypeService,
        private serviceService: ServicesService
    ) {
        Chart.register(...registerables);
    }

    getStats(appSetting: any) {
        new Chart("myChart1", {
            type: "line",
            data: {
                labels: appSetting.companies_by_year.series.name,
                datasets: [
                    {
                        label: appSetting.companies_by_year.name,
                        data: appSetting.companies_by_year.series.value,
                        borderWidth: 2,
                        backgroundColor: "#00533e",
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
        
        new Chart("myChart2", {
            type: "line",
            data: {
                labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
                datasets: [
                    {
                        label: "Nombre de PME/PMI benchmarchées",
                        data: [12, 19, 3, 5, 2, 3, 50],
                        borderWidth: 2,
                        backgroundColor: "#00533e",
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
        
        new Chart("myChart3", {
            type: "line",
            data: {
                labels: appSetting.requests_by_year.series.name,
                datasets: [
                    {
                        label: appSetting.requests_by_year.name,
                        data: appSetting.requests_by_year.series.value,
                        borderWidth: 2,
                        backgroundColor: "#00533e",
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
        
        new Chart("myChart4", {
            type: "line",
            data: {
                labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
                datasets: [
                    {
                        label: "Nombre de PME/PMI accompagnées",
                        data: [12, 19, 3, 5, 2, 3, 50],
                        borderWidth: 2,
                        backgroundColor: "#00533e",
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });

        new Chart("myChart5", {
            type: "line",
            data: {
                labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
                datasets: [
                    {
                        label: "Nombre de mises en relation",
                        data: [12, 19, 3, 5, 2, 3, 50],
                        borderWidth: 2,
                        backgroundColor: "#00533e",
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });

        new Chart("myChart6", {
            type: "line",
            data: {
                labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
                datasets: [
                    {
                        label: "Nombre de PME/PMI contractualisées",
                        data: [12, 19, 3, 5, 2, 3, 50],
                        borderWidth: 2,
                        backgroundColor: "#00533e",
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
        new Chart("myChart7", {
            type: "line",
            data: {
                labels: appSetting.buyers_by_year.series.name,
                datasets: [
                    {
                        label: appSetting.buyers_by_year.name,
                        data: appSetting.buyers_by_year.series.value,
                        borderWidth: 2,
                        backgroundColor: "#00533e",
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
        new Chart("myChart8", {
            type: "line",
            data: {
                labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
                datasets: [
                    {
                        label: "Nombre total de contrats",
                        data: [12, 19, 3, 5, 2, 3, 50],
                        borderWidth: 2,
                        backgroundColor: "#00533e",
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
        new Chart("myChart9", {
            type: "line",
            
            data: {
                labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
                
                datasets: [
                    {
                        label: "Montant total des contrats (En milliards de GNF)",
                        data: [12, 19, 3, 5, 2, 3, 50],
                        borderWidth: 2,
                        backgroundColor: "#00533e",
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
    }

    ngOnInit(): void {
        // $.getScript('assets/js/custom.js')
        this.target = this.appSettingService.value.total_supplier;
        this.target1 = this.appSettingService.value.total_request;
        // this.target = this.appSettingService.value.total_buyer;
        // this.target = this.appSettingService.value.total_supplier;
        // this.startCounting();
        // this.startCounting1();

        setTimeout(() => this.getStats(this.appSettingService.value), 1000);

        this.meta.addTag({ name: "description", content: "Accueil" });
        this.meta.addTag({
            name: "keywords",
            content: "BSTP Guinée, BSTP, bstpgn, ",
        });
        this.meta.addTag({ name: "author", content: "BSTP Guinée" });
        
        if(this.authService.value != null) {

            if (this.authService.isAuthenticated()) {
                const authToken = this.jwtHelper.decodeToken();
                if (authToken.role == "supplier") {
                    this.router.navigate(["/supplier/dashboard"]);
                } else if (authToken.role == "buyer") {
                    this.router.navigate(["/buyer/dashboard"]);
                }
            }
        }

        this.articleService.limit().subscribe({
            next: (response) => {
                this.articles = response;
            },
            error: (error) => {},
        });

        this.typeRequestService.list().subscribe({
            next: (response) => {
                this.requestTypes = response;
            },
            error: (error) => {},
        });

        this.serviceService
            .list()
            .subscribe((response) => (this.services = response));
    }


    format(value) {
        return numberWithSpaces(value);
    }

    startState(value: number) {
        return startCounting(value);
    }

}
