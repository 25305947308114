<footer>
    <div class="container">
        <!-- <div class="subscribe-area">
            <div class="section-title">
                <h2>Souscrivez aux Newsletters</h2>
            </div>

            <div class="subscribe-shape">
                <img src="./assets/img/shape/1.png" alt="Shape">
                <img src="./assets/img/shape/1.png" alt="Shape">
                <img src="./assets/img/shape/1.png" alt="Shape">
                <img src="./assets/img/shape/1.png" alt="Shape">
                <img src="./assets/img/shape/1.png" alt="Shape">
            </div>

            <div class="subscribe-item">
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Entrez votre Adresse Email" name="EMAIL" required autocomplete="off">
                    <button class="btn subscribe-btn" type="submit">ENVOYEZ</button>
                </form>
            </div>
        </div> -->

        <div class="row pt-5">
            <div class="col-sm-6 col-lg-6">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img [src]="appSettingService.value.footer_logo" width="200" alt="Logo"></a>
                        <p style="text-align: justify;">{{ appSettingService.value.short_description }}</p>
                        <ul>
                            <li><a [href]="appSettingService.value.youtube_url" title="Youtube" target="_blank"><i class="icofont-youtube-play"></i></a></li>
                            <li><a [href]="appSettingService.value.facebook_url" title="Facebook" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a [href]="appSettingService.value.instagram_url" title="Instagram" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a [href]="appSettingService.value.twiter_url" title="Twitter" target="_blank"><i class="icofont-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-category">
                        <h3>RACCOURCIS</h3>

                        <ul> 
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> ACCUEIL</a></li>
                            <li><a routerLink="/about"><i class="icofont-simple-right"></i> A PROPOS DE LA BSTP</a></li>
                            <li><a routerLink="/blog"><i class="icofont-simple-right"></i> ACTUALITÉS</a></li>
                            <li><a routerLink="/missions"><i class="icofont-simple-right"></i> MISSIONS</a></li>
                            <li><a routerLink="/guides"><i class="icofont-simple-right"></i> GUIDES D'UTILISATION</a></li>
                            <li><a routerLink="/faq"><i class="icofont-simple-right"></i> FAQs</a></li>
                            <!-- <li><a routerLink="/create-old-company"><i class="icofont-simple-right"></i> ENTREPRISE EXISTANTE</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>CONTACTEZ-NOUS</h3>

                        <ul>
                            <li><i class="icofont-location-pin"></i> {{ appSettingService.value.address }} </li>
                            <li><i class="icofont-ui-call"></i> <a href="tel:{{ appSettingService.value.phone_number1 }}"> {{ appSettingService.value.phone_number1 }} </a></li>
                            <li><i class="icofont-ui-call"></i> <a href="tel:{{ appSettingService.value.phone_number2 }}"> {{ appSettingService.value.phone_number2 }} </a></li>
                            <li><i class="icofont-ui-email"></i> <a href="mailto:{{ appSettingService.value.email }}"> {{ appSettingService.value.email }} </a></li>
                        </ul>
                        <div class="newsletter js-rollover mt-5" data-radius="50">
                            <span class="text-white">S’inscrire à la newsletter</span>
                            <form method="GET" action="https://kikk.us6.list-manage.com/subscribe" class="newsletter-form" target="_blank">
                              <input type="hidden" name="u" value="d08fe605a9149dc54a3c13f44">
                              <input type="hidden" name="id" value="96f67efdeb">
                              <input type="email" required name="Email" id="email" placeholder="Entrer votre email">
                              <button type="submit" class="button shadow">S'abonner</button>
                            </form>
                          </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <p>&COPY; {{ appSettingService.value.copyright }}</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item copyright-right">
                        <ul>
                            <li><a routerLink="/terms-and-conditions">Termes & Conditions d'Utilisation</a></li>
                            <li><span>-</span></li>
                            <li><a routerLink="/privacy-policy">Politique de Confidentialité</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>