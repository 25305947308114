<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>CHANGEMENT DE MOT DE PASSE</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>CHANGEMENT DE PASSE</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="login-area pt-5">
    
    <div class="container mt-5">
        <div class="login-page">
            <div class="form">
                <form
                    class="login-form"
                    [formGroup]="form"
                    (ngSubmit)="onSubmit()"
                >

                    <div class="form-group col-lg-6 text-start">
                        <label for="password1">Nouveau Mot de passe <span class="text-danger">(OBLIGATOIRE)</span></label>
                        <input
                            type="password"
                            class="form-control"
                            placeholder="password"
                            formControlName="password1"
                            placeholder="Saisissez votre nouveau mot de passe"
                        />
                    </div>

                    <div class="form-group col-lg-6 text-start">
                        <label for="password2">Confirmer le Nouveau Mot de passe <span class="text-danger">(OBLIGATOIRE)</span></label>
                        <input
                            type="password"
                            class="form-control"
                            placeholder="password"
                            formControlName="password2"
                            placeholder="Confirmer votre nouveau mot de passe"
                        />
                    </div>

                    <re-captcha
                        class="w-100"
                        id="recaptcha"
                        formControlName="recaptcha"
                    ></re-captcha>

                    <button type="submit" class="mt-5 btn btn-green" [disabled]="form.invalid || loading">
                        CONFIRMER LE CHANGEMENT <i *ngIf="loading" class="icofont-spinner-alt-1 icofont-spin"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>