<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>TOUTE L'ACTUALITÉ</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>TOUTE L'ACTUALITÉ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area pt-5 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="text-default" style="font-weight: bold;">À la Une</h2>
            </div>
            <div class="col-lg-7 pt-3">
                <div id="carouselExampleCaptions" class="carousel slide rounded-5" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <ng-container *ngFor="let article of limites; index as i">
                            <div class="carousel-item" style="height: 378px;" [ngClass]="i == 0 ? 'active' : ''">
                              <img [src]="article.media" [alt]="article.title" class="d-block w-100">
                              <div class="carousel-caption text-start">
                                <h5 class="text-white" style="font-size: 20px; font-weight: bold;">{{ article.title }}</h5>
                              </div>
                            </div>
                        </ng-container>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container">
        <form class="search-form">
            <input type="email" class="form-control" placeholder="Rechercher par le titre ou la catégorie" [(ngModel)]="search" name="search" autocomplete="off">
            <button class="btn subscribe-btn" type="submit"><i class="icofont-search" style="font-weight: bold;"></i></button>
        </form>
    </div> -->

    <div class="container pt-5 mb-100" *ngIf="articles">
        <div class="row">
            <div class="col-lg-12 pb-3">
                <h2 class="text-default" style="font-weight: bold;">Acualités</h2>
            </div>
            <div class="col-sm-6 col-lg-4" *ngFor="let article of filteredTeam">
                <div class="blog-item wow fadeInUp" data-wow-delay=".3s">
                    <div class="blog-top">
                        <a [routerLink]="article.slug"><img [src]="article.media" height="278" [alt]="article.title"></a>
                        <span>{{ article.publishon_on|date:'d MMMM yyyy' }}</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a [routerLink]="article.slug"> {{ article.title }} </a></h3>
                        <ul>
                            <!-- <li><img src="assets/img/home-1/blog/1.png" alt="Blog">{{ article.author.first_name }}</li> -->
                            <li><a [routerLink]="article.slug">Lire Plus<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="articles">
        <div class="container" *ngIf="articles.length == 0">
            <div class="text-center">
                <img src="./assets/img/notdatafound.png" alt="" width="400"/>
                <div class="pt-5 pb-100">
                    <h4>Pas d'articles trouvés</h4>
                </div>
            </div>
        </div>
    </ng-container>
</section>