<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>ADRESSE EMAIL DÉJÀ CONFIRMÉE</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>ADRESSE EMAIL DÉJÀ CONFIRMÉÉ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="post-job-area pt-5 pb-100 mt-5">
    <div class="container text-left">
        <img src="assets/guides/02.png" alt="" width="150" />
        <div class="pt-5">
            <h4>Adresse Email déjà Confirmée</h4>
            <h5>Cher(e) fournisseur,</h5>
            <p>
                Votre compte a été créé avec succès. Vous pouvez maintenant vous
                connecter à votre compte en utilisant votre adresse email et
                votre mot de passe.
            </p>
            <p>
                Nous vous remercions pour votre inscription et nous vous
                souhaitons la bienvenue.
            </p>
            <p>Cordialement,</p>
            <p>L'équipe de support de la Bourse des Sous-Traitance et de Partenariats</p>
            <div class="pt-100">
                <button routerLink="/login" class="btn btn-outline-main" type="submit">
                    Continuer <i class="icofont-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</div>
