import { Component, Input, OnInit } from '@angular/core';
import { AppSettingService } from 'src/services/app-setting/app-setting.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  constructor(public appSettingService: AppSettingService) { }
}
