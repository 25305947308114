<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>FORMULAIRE D'AJOUT D'ENTREPRISE EXISTANTE</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>FORMULAIRE D'AJOUT D'ENTREPRISE EXISTANTE</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="post-job-area pt-100">
    <div class="container">
        <form enctype="multipart/form-data" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="post-job-item shadow mb-5">
                <h3>INFORMATIONS DU DEMANDEUR</h3>
                <hr />
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>PRÉNOM
                                <span class="text-danger">*</span></label>
                            <input formControlName="first_name" type="text" class="form-control" />
                            <small class="text-bg-danger" *ngIf="
                                    form.get('first_name').touched &&
                                    form.get('first_name').hasError('required')
                                ">Champs obligatoire.</small>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>NOM <span class="text-danger">*</span></label>
                            <input formControlName="last_name" type="text" class="form-control" />
                            <small class="text-bg-danger" *ngIf="
                                    form.get('last_name').touched &&
                                    form.get('last_name').hasError('required')
                                ">Champs obligatoire.</small>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <div class="job-type-area">
                                <span>SEXE</span>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="gender" formControlName="gender"
                                        id="male" value="M" checked />
                                    <label class="form-check-label" for="male">
                                        HOMME</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="gender" formControlName="gender"
                                        id="female" value="F" />
                                    <label class="form-check-label" for="female">
                                        FEMME</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>DATE DE NAISSANCE
                                <span class="text-danger">*</span></label>
                            <input formControlName="date_of_birth" type="date" class="form-control" />
                            <small class="text-bg-danger" *ngIf="
                                    form.get('date_of_birth').touched &&
                                    form
                                        .get('date_of_birth')
                                        .hasError('required')
                                ">Champs obligatoire.</small>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>NATIONALITÉ
                                <span class="text-danger">*</span></label>
                            <select formControlName="nationality" class="form-select">
                                <option *ngFor="let country of countryService.value" [value]="country.id">
                                    {{ country.name }}
                                </option>
                            </select>
                            <small class="text-bg-danger" *ngIf="
                                    form.get('nationality').touched &&
                                    form.get('nationality').hasError('required')
                                ">Champs obligatoire.</small>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>EMAIL <span class="text-danger">*</span></label>
                            <input formControlName="email" type="email" class="form-control" />
                            <small class="text-bg-danger" *ngIf="
                                    form.get('email').touched &&
                                    form.get('email').hasError('required')
                                ">Champs obligatoire.</small>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>FONCTION
                                <span class="text-danger">*</span></label>
                            <input formControlName="profession" type="profession" class="form-control" />
                            <small class="text-bg-danger" *ngIf="
                                    form.get('profession').touched &&
                                    form.get('profession').hasError('required')
                                ">Champs obligatoire.</small>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>TELEPHONE
                                <span class="text-danger">*</span></label>
                            <input formControlName="phone_number" placeholder="ex: 622 00 00 00" mask="000 00 00 00" type="text"
                                class="form-control" />
                            <small class="text-bg-danger" *ngIf="
                                    form.get('phone_number').touched &&
                                    form
                                        .get('phone_number')
                                        .hasError('required')
                                ">Champs obligatoire.</small>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>ADRESSE</label>
                            <input formControlName="address" type="text" class="form-control" />

                        </div>
                    </div>

                    <!-- <div class="col-lg-12" *ngIf="">
                            <div class="alert alert-danger d-flex align-items-center border-0" role="alert">
                                <i class="icofont-warning"></i>
                                <div>
                                  Le mot de passe saisi est incorrecte.
                                </div>
                            </div>
                        </div> -->
                </div>
            </div>
            <div class="post-job-item shadow mb-5">
                <div class="create-education-wrap">
                    <div class="create-education-left">
                        <h3>INFORMATIONS DE L'ENTREPRISE</h3>
                    </div>
                    <hr />
                    <div class="row" formGroupName="supplier">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>NOM DE L'ENTREPRISE
                                    <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="name" />
                                <small class="text-bg-danger" *ngIf="
                                        form.get('supplier').get('name').touched &&
                                        form
                                            .get('supplier')
                                            .get('name')
                                            .hasError('required')
                                    ">Champs obligatoire.</small>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>N° RCCM
                                    <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="rccm" />
                                <small class="text-bg-danger" *ngIf="
                                        form.get('supplier').get('rccm').touched &&
                                        form
                                            .get('supplier')
                                            .get('rccm')
                                            .hasError('required')
                                    ">Champs obligatoire.</small>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>DATE DE CRÉATION
                                    <span class="text-danger">*</span></label>
                                <input formControlName="year_of_registration" type="date" class="form-control" />
                                <small class="text-bg-danger" *ngIf="
                                        form
                                            .get('supplier')
                                            .get('year_of_registration')
                                            .touched &&
                                        form
                                            .get('supplier')
                                            .get('year_of_registration')
                                            .hasError('required')
                                    ">Champs obligatoire.</small>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <div class="job-type-area">
                                    <label>FORMES JURIDIQUES
                                        <i class="text-danger">*</i></label>
                                    <small class="text-bg-danger" *ngIf="
                                            form.get('supplier').get('type')
                                                .touched &&
                                            form
                                                .get('supplier')
                                                .get('type')
                                                .hasError('required')
                                        ">Champs obligatoire.</small>

                                    <div class="row">
                                        <div class="col-lg-4 mb-3" *ngFor="
                                                let companyType of companyTypes
                                            ">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" formControlName="type"
                                                    name="type" [id]="companyType.id" [value]="companyType.id" />
                                                <label class="form-check-label" [for]="companyType.id">{{
                                                    companyType.name
                                                    }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>DESCRIPTION
                                    <span class="text-danger">*</span></label>
                                <div class="NgxEditor__Wrapper">
                                    <ngx-editor-menu [editor]="editor">
                                    </ngx-editor-menu>
                                    <ngx-editor formControlName="description" cols="30" [editor]="editor"
                                        [ngModel]="html" [placeholder]="'Saisissez ici...'"></ngx-editor>
                                </div>
                                <small class="text-bg-danger" *ngIf="
                                        form.get('supplier').get('description')
                                            .touched &&
                                        form
                                            .get('supplier')
                                            .get('description')
                                            .hasError('required')
                                    ">Champs obligatoire.</small>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">

                                <div class="row mb-5 mt-2">
                                    <div class="col-lg-6">
                                        <label>
                                            CHOISISSEZ AU MAXIMUM CINQ (5) DOMAINES
                                            D'ACTIVITÉ AFIN D'AVOIR ACCÈS AUX APPELS
                                            D'OFFRES
                                            <span class="text-danger">*</span>
                                        </label><br />
                                        <small class="text-bg-danger" *ngIf="
                                                form.get('supplier').get('categories')
                                                    .touched &&
                                                form
                                                    .get('supplier')
                                                    .get('categories')
                                                    .hasError('required')
                                            ">Champs obligatoire.</small>

                                    </div>
                                    <div class="col-lg-6">
                                        <label>FILTRER PAR LIBELLÉ</label>
                                        <input type="text" class="form-control" [(ngModel)]="search"
                                            formControlName="search"
                                            placeholder="Filtrer par le libellé de la catégorie" name="search"
                                            autocomplete="on">
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-lg-4 col-md-6" *ngFor="
                                            let category of filteredCategories;
                                            let i = index
                                        ">
                                        <label>
                                            <input type="checkbox" [value]="category.id" class="form-check-input"
                                                (change)="
                                                    onCheckboxChange($event)
                                                " />
                                            {{ category.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>SITE INTERNET</label>
                                <input type="url" formControlName="website" class="form-control" />
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>TELEPHONE
                                    <span class="text-danger">*</span></label>
                                <input type="tel" mask="000 00 00 00" class="form-control"
                                    formControlName="phone_number" />
                                <small class="text-bg-danger" *ngIf="
                                        form.get('supplier').get('phone_number')
                                            .touched &&
                                        form
                                            .get('supplier')
                                            .get('phone_number')
                                            .hasError('required')
                                    ">Champs obligatoire.</small>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>EMAIL DE L'ENTREPRISE
                                    <span class="text-danger">*</span></label>
                                <input type="email" formControlName="email" class="form-control" />
                                <small class="text-bg-danger" *ngIf="
                                        form.get('supplier').get('email')
                                            .touched &&
                                        form
                                            .get('supplier')
                                            .get('email')
                                            .hasError('required')
                                    ">Champs obligatoire.</small>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>RÉGION
                                    <span class="text-danger">*</span></label>
                                <select formControlName="region" class="form-select"
                                    (change)="onRegionsChanged($event)">
                                    <option value="null" selected>
                                        Selectionnez une region
                                    </option>
                                    <option *ngFor="
                                            let region of regionService.value
                                        " [value]="region.id">
                                        {{ region.name }}
                                    </option>
                                </select>
                                <small class="text-bg-danger" *ngIf="
                                form.get('supplier').get('region').touched &&
                                form
                                    .get('supplier')
                                    .get('region')
                                    .hasError('required')
                            ">Champs obligatoire.</small>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>PREFECTURE</label>
                                <select class="form-select" formControlName="prefecture"
                                    (change)="onPrefecturesChanged($event)">
                                    <option value="null" selected>
                                        Selectionnez une prefecture
                                    </option>
                                    <option *ngFor="let prefecture of prefectures" [value]="prefecture.id">
                                        {{ prefecture.name }}
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>COMMUNE</label>
                                <select formControlName="commune" class="form-select">
                                    <option value="null" selected>
                                        Selectionnez une commune
                                    </option>
                                    <option *ngFor="let commune of communes" [value]="commune.id">
                                        {{ commune.name }}
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>ADRESSE PRINCIPALE
                                    <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="address" />
                                <small class="text-bg-danger" *ngIf="
                                        form.get('supplier').get('address')
                                            .touched &&
                                        form
                                            .get('supplier')
                                            .get('address')
                                            .hasError('required')
                                    ">Champs obligatoire.</small>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <small class="text-bg-danger" *ngIf="
                                        form.get('supplier').get('terms_and_conditions').touched &&
                                        form
                                            .get('supplier')
                                            .get('terms_and_conditions')
                                            .hasError('required')
                                    ">Champs obligatoire.</small>
                                <label>
                                    <input type="checkbox" value="true" class="form-check-input"
                                        formControlName="terms_and_conditions" />
                                    En vous inscrivant, vous reconnaissez avoir
                                    lu et accepté les
                                    <a class="text-default" target="_blank" routerLink="/terms-and-conditions">Termes et
                                        Conditions d'utilisation</a>
                                    et la
                                    <a class="text-default" target="_blank" routerLink="/privacy-policy">Politique de
                                        Confidentialité</a>, telle qu'elle est modifiée de temps à
                                    autre.
                                </label>
                            </div>

                        </div>

                        <re-captcha class="w-100" id="recaptcha" formControlName="recaptcha"></re-captcha>
                        <small class="text-bg-danger" *ngIf="
                                form.get('supplier').get('recaptcha').touched &&
                                form
                                    .get('supplier')
                                    .get('recaptcha')
                                    .hasError('required')
                            ">Champs obligatoire.</small>

                        <!-- <div class="col-lg-6 col-md-12"> 
                            <div class="form-group">
                                <label>LOGO DE L'ENTREPRISE</label>
                                <input accept="image/*" (change)="onFileChange('logo', $event)" type="file" class="form-control">
                            </div>
                        </div> -->

                        <div class="col-lg-12 col-md-12 mt-5 text-center">
                            <button type="submit" class="btn btn-main btn-block" [disabled]="loading">
                                CRÉER MAINTENANT <i *ngIf="loading" class="icofont-spinner-alt-1 icofont-spin"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>


<!-- <app-loading [loading]="loading" /> -->
