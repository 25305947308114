<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>EQUIPE DE LA BSTP</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>EQUIPE DE LA BSTP</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="single-profile-area pt-5">
    <div class="container">
        <form class="search-form">
            <input type="email" class="form-control" [(ngModel)]="search" placeholder="Saisissez le nom, prenom de la personne" name="search" autocomplete="off">
            <button class="btn subscribe-btn" title="Recherche" type="submit"><i class="icofont-search" style="font-weight: bold;"></i></button>
        </form>
    </div>
    <div class="container pt-5">
        <div class="row pb-5" *ngFor="let staff of filteredTeam">
            <div class="col-lg-3">
                <div class="instructor-img">
                    <img [src]="staff.photo" [alt]="staff.full_name">
                </div>
            </div>

            <div class="col-lg-9">
                <div class="instructor-content">
                    <h3> {{ staff.full_name }} </h3>
                    <h5>{{ staff.fonction.name }}</h5>
                    
                    <div style="text-align: justify;" [innerHTML]="staff.description"></div>
                    <!-- <a routerLink="/appointment" class="default-btn1" >Book Appointment</a> -->
                </div>
            </div>
        </div>
    </div>
</div>