import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { AuthService } from 'src/services/auth/auth.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard implements CanActivate {
  constructor(
    public auth: AuthService,
    public jwtHelper: JwtHelperService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const roles = route.data['role'];
    // console.log(roles)
    const payload = this.jwtHelper.decodeToken();
    
    // console.log(payload)
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] == payload.role) {
        // console.log(payload.role)
        return true;
      }
    }
    
    // Swal.fire({
    //   title: "Autorisation",
    //   icon: 'error',
    //   text: "Votre droit ne vous permet pas d'afficher cette page.",
    //   // backdrop: false,
    //   confirmButtonText: "Fermer",
    //   allowEscapeKey: () => false,
    //   allowOutsideClick: () => false,
    // })
    return false;
  }

}
