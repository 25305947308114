import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PartenaireType } from 'src/models/partenaire-type';
import { PartenaireTypeService } from 'src/services/partenaire-type/partenaire-type.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  partenairesType: Array<PartenaireType>;
  loading: boolean;
  search: string;

  constructor(private partenaireTypeService: PartenaireTypeService, private meta: Meta) {}

  ngOnInit(): void {
    this.meta.addTag({ name: 'description', content: "Acheteurs & Partenaires" });
    this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn, ' });
    this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });

      this.loading = true;
      this.partenaireTypeService.list().subscribe({
        next: data => {
          this.partenairesType = data;
          this.loading = false;
        },
        error: error => this.loading = false,
      });
  }

  get filteredPartenaires() {
    if (this.search === null || this.search === undefined || this.search === '') {
        return this.partenairesType;
    }
    return this.partenairesType.filter(item =>
      item.name.toLowerCase().includes(this.search.toLowerCase()) ||
      item.partenaires.some(p =>
        p.name.toLowerCase().includes(this.search.toLowerCase())
      )
    );
  }
}
