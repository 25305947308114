<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>ERREUR 404</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>ERREUR 404</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <h1>404!</h1>
                    <p>Désolé! La page n'a pas été trouvée</p>
                    <span>Oops! Il semble que cette page n'existe pas dans le programme, veuillez contacter l'administrateur.</span>
                    <a routerLink="/">Retour à la page d'accueil</a>
                </div>
            </div>
        </div>
    </div>
</section>