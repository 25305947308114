import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Buyer } from 'src/models/buyer';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  constructor(private http: HttpClient) { }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}buyer/`) as Observable<any>;
  }

  currentBuyerInformation(): Observable<any> {
    return this.http.get(`${environment.apiUrl}buyer/current_buyer_info/`) as Observable<any>;
  }  
  
  update(slug: string, value: any): Observable<Buyer> {
    return this.http.put(`${environment.apiUrl}buyer/${slug}/`, value) as Observable<Buyer>;
  }

}
