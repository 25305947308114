import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Region } from 'src/models/region';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  private regionsSubject: BehaviorSubject<Array<Region>>;
  public regions$: Observable<Array<Region>>;

  constructor(private http: HttpClient) {
    this.regionsSubject = new BehaviorSubject<Array<Region>>([]);
    this.regions$ = this.regionsSubject.asObservable();
  }

  public get value(): Array<Region> {
    return this.regionsSubject.value;
  }

  update(Regions: Array<Region>){
    this.regionsSubject.next(Regions);
  }
  
  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}region/`) as Observable<any>;
  }
}
