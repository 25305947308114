import { Component } from '@angular/core';

@Component({
  selector: 'app-mail-confirmed',
  templateUrl: './mail-confirmed.component.html',
  styleUrls: ['./mail-confirmed.component.scss']
})
export class MailConfirmedComponent {

}
