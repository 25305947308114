import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Staff } from 'src/models/staff.model';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  private staffsSubject: BehaviorSubject<Array<Staff>>;
  public staffs$: Observable<Array<Staff>>;

  constructor(private http: HttpClient) {
    this.staffsSubject = new BehaviorSubject<Array<Staff>>([]);
    this.staffs$ = this.staffsSubject.asObservable();
  }

  public get value(): Array<Staff> {
    return this.staffsSubject.value;
  }

  update(staffs: Array<Staff>){
    this.staffsSubject.next(staffs);
  }
  
  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}staff/`) as Observable<any>;
  }
  
}
