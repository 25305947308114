import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Requests } from 'src/models/requests';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  constructor(private http: HttpClient) { }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}request/`) as Observable<any>;
  }

  filter(categories: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}request/?categories__slug=${categories}&status=published`) as Observable<any>;
  }

  all(): Observable<any> {
    return this.http.get(`${environment.apiUrl}request/all/`) as Observable<any>;
  }

  create(data: Requests): Observable<any> {
    return this.http.post(`${environment.apiUrl}request/`, data) as Observable<any>;
  }

  publish(slug: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}request/publish/${slug}/`) as Observable<any>;
  }

  abord(slug: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}request/abord/${slug}/`) as Observable<any>;
  }

  edit(slug: string, payload: Requests): Observable<any> {
    return this.http.put(`${environment.apiUrl}request/edit/${slug}/`, payload) as Observable<any>;
  }

  get(slug: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}request/${slug}/`) as Observable<any>
  }

  update(slug: string, data: Requests): Observable<any> {
    return this.http.put(`${environment.apiUrl}request/${slug}/`, data) as Observable<any>;
  }

  getAllRequestByBuyers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}request/get_all_request_by_buyers/`) as Observable<any>;
  }

}
