import { Component, Input, OnInit } from '@angular/core';
import { AppSettingService } from 'src/services/app-setting/app-setting.service';
import { AuthService } from 'src/services/auth/auth.service';

@Component({
  selector: 'app-buyer-navbar',
  templateUrl: './buyer-navbar.component.html',
  styleUrls: ['./buyer-navbar.component.scss']
})
export class BuyerNavbarComponent {

  constructor(public authService: AuthService, public appSettingService: AppSettingService) {}
}
