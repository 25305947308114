import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Countries } from 'src/models/countries';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  private countriesSubject: BehaviorSubject<Array<Countries>>;
  public countries$: Observable<Array<Countries>>;

  constructor(private http: HttpClient) {
    this.countriesSubject = new BehaviorSubject<Array<Countries>>([]);
    this.countries$ = this.countriesSubject.asObservable();
  }

  public get value(): Array<Countries> {
    return this.countriesSubject.value;
  }

  update(countries: Array<Countries>){
    this.countriesSubject.next(countries);
  }
  
  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}countries/`) as Observable<any>;
  }

  all(): Observable<Array<Countries>> {
    return this.http.get(`${environment.apiUrl}countries/all/`) as Observable<Array<Countries>>;
  }
}
