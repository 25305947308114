import { Component } from '@angular/core';
import { AppSettingService } from 'src/services/app-setting/app-setting.service';
import { AuthService } from 'src/services/auth/auth.service';

@Component({
  selector: 'app-organisme-navbar',
  templateUrl: './organisme-navbar.component.html',
  styleUrls: ['./organisme-navbar.component.scss']
})
export class OrganismeNavbarComponent {

  constructor(public authService: AuthService, public appSettingService: AppSettingService) {}

}
