import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutComponent } from "./components/pages/about/about.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { CreateAccountComponent } from "./components/pages/create-account/create-account.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TermsAndConditionsComponent } from "./components/pages/terms-and-conditions/terms-and-conditions.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { SubscriptionSuccessComponent } from "./components/pages/subscription-success/subscription-success.component";
import { FeedbackComponent } from "./components/pages/feedback/feedback.component";
import { AuthGuard } from "src/guards/auth.guard";
import { RolesGuard } from "src/guards/roles.guard";
import { HomeComponent } from "./components/pages/home/home.component";
import { QuicklinkStrategy } from 'ngx-quicklink';
import { RequestsComponent } from "./components/pages/requests/requests.component";
import { RequestDetailComponent } from "./components/pages/request-detail/request-detail.component";
import { MissionsComponent } from "./components/pages/missions/missions.component";
import { BstpTeamComponent } from "./components/pages/bstp-team/bstp-team.component";
import { GuidesComponent } from "./components/pages/guides/guides.component";
import { PartnersComponent } from "./components/pages/partners/partners.component";
import { MailConfirmedComponent } from "./components/pages/mail-confirmed/mail-confirmed.component";
import { PaymentFirstStepComponent } from "./components/pages/payment-first-step/payment-first-step.component";
import { MailUnconfirmedComponent } from "./components/pages/mail-unconfirmed/mail-unconfirmed.component";
import { MailAlreadyConfirmedComponent } from "./components/pages/mail-already-confirmed/mail-already-confirmed.component";
import { ForgotPasswordComponent } from "./components/pages/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./components/pages/change-password/change-password.component";
import { EmailSentSuccessfullyComponent } from "./components/pages/email-sent-successfully/email-sent-successfully.component";
import { PaymentSuccessfulComponent } from "./components/pages/payment-successful/payment-successful.component";
import { CreateOldCompanyComponent } from "./components/pages/create-old-company/create-old-company.component";
import { LabelisationDesPmePmiComponent } from "./components/pages/labelisation-des-pme-pmi/labelisation-des-pme-pmi.component";
import { ProgrammeDAmeliorationDeContenuComponent } from "./components/pages/programme-d-amelioration-de-contenu/programme-d-amelioration-de-contenu.component";
import { MaintenanceComponent } from "./components/pages/maintenance/maintenance.component";

const routes: Routes = [
    { path: "", component: HomeComponent, title: "BSTP | ACCUEIL" },
    { path: "about", component: AboutComponent, title: "BSTP | A PROPOS DE LA BSTP" },
    { path: "login", component: LoginComponent, title: "BSTP | CONNEXTION" },
    { path: "create-account", component: CreateAccountComponent, title: "BSTP | FORMULAIRE DE DEMANDE DE CRÉATION D'UNE ENTREPRISE" },
    { path: "404", component: ErrorComponent, title: "BSTP | ERREUR 404" },
    { path: "faq", component: FaqComponent, title: "BSTP | FAQs" },
    { path: "terms-and-conditions", component: TermsAndConditionsComponent, title: "BSTP | TERMES ET CONDITIONS D'UTILISATION" },
    { path: "privacy-policy", component: PrivacyPolicyComponent, title: "BSTP | POLITIQUE DE CONFIDENTIALITÉ" },
    { path: "blog", component: BlogComponent, title: 'BSTP | ACTUALITÉS' },
    { path: "blog/:slug", component: BlogDetailsComponent },
    { path: "contact", component: ContactComponent, title: "BSTP | CONTACTEZ-NOUS" },
    {
        path: 'labelisation-des-pme-pmi',
        component: LabelisationDesPmePmiComponent,
        title: "BSTP | LABELISATION DES PME/PMI",
        data: {
            preload: true,
        }
    },
    {
        path: 'programme-d-amelioration-de-contenu',
        component: ProgrammeDAmeliorationDeContenuComponent,
        title: "BSTP | PROGRAMME D'AMELIORATION DE CONTENU",
        data: {
            preload: true,
        }
    },
    {
        path: 'supplier',
        loadChildren: () => import('./components/pages/supplier/supplier.module').then(m => m.SupplierModule),
        data: {
            preload: true,
            role: ['supplier', 'supplier_collegue']
        },
        canActivate: [AuthGuard, RolesGuard]
    },
    {
        path: 'buyer',
        loadChildren: () => import('./components/pages/buyer/buyer.module').then(m => m.BuyerModule),
        data: {
            preload: true,
            role: ['buyer', 'buyer_collegue']
        },
        canActivate: [AuthGuard, RolesGuard]
    },
    {
        path: 'organisme',
        loadChildren: () => import('./components/pages/organisme/organisme.module').then(m => m.OrganismeModule),
        data: {
            preload: true,
            role: ['cabinet']
        },
        canActivate: [AuthGuard, RolesGuard]
    },
    {
        path: 'labelisation',
        loadChildren: () => import('./components/pages/labelisation/labelisation.module').then(m => m.LabelisationModule),
        data: {
            preload: true,
            role: ['supplier', 'supplier_collegue']
        },
        canActivate: [AuthGuard, RolesGuard]
    },
    {
        path: "registration-success",
        component: SubscriptionSuccessComponent,
        title: "BSTP | SUCCES",
        data: {
            preload: true
        }
    },
    {
        path: 'feedback',
        component: FeedbackComponent,
        title: 'BSTP | FEEDBACK',
        data: {
            role: ['supplier', 'buyer', 'supplier_collegue', 'buyer_collegue'],
            preload: true
        },
        canActivate: [AuthGuard, RolesGuard]
    },
    {
        path: 'requests',
        component: RequestsComponent,
        title: "BSTP | APPELS D'OFFRE",
        data: {
            preload: true,
        },
    },
    {
        path: 'requests/:slug',
        component: RequestDetailComponent,
        title: "BSTP | APPELS D'OFFRE DETAIL",
        data: {
            preload: true,
        },
        canActivate: [AuthGuard, RolesGuard]
    },
    {
        path: 'missions',
        component: MissionsComponent,
        title: "BSTP | MISSIONS",
        data: {
            preload: true,
        }
    },
    {
        path: 'bstp-team',
        component: BstpTeamComponent,
        title: "BSTP | EQUIPE DE LA BSTP"
    },
    {
        path: 'guides',
        component: GuidesComponent,
        title: "BSTP | GUIDES D'UTILISATION",
        data: {
            preload: true,
        }
    },
    {
        path: 'partners',
        component: PartnersComponent,
        title: "BSTP | PARTENAIRES DE LA BSTP",
        data: {
            preload: true,
        }
    },
    {
        path: 'mail-confirmed',
        component: MailConfirmedComponent,
        title: "BSTP | Vérification de l'Email Réussie",
        data: {
            preload: true,
        }
    },
    {
        path: 'mail-already-confirmed',
        component: MailAlreadyConfirmedComponent,
        title: "BSTP | Vérification de l'Email Déjà Confirmée",
        data: {
            preload: true,
        }
    },
    {
        path: 'mail-unconfirmed',
        component: MailUnconfirmedComponent,
        title: "BSTP | Vérification de l'Email Échouée",
        data: {
            preload: true,
        }
    },
    {
        path: 'payment-first-step',
        component: PaymentFirstStepComponent,
        title: "BSTP | Procédez au Paiement",
        data: {
            preload: true,
        }
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        title: "BSTP | MOT DE PASSE OUBLIÉ",
        data: {
            preload: true,
        }
    },
    {
        path: 'renew-password/:uidb64/:token',
        component: ChangePasswordComponent,
        title: "BSTP | CHANGEMENT DE MOT DE PASSE",
        data: {
            preload: true,
        }
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        title: "BSTP | CHANGEMENT DE MOT DE PASSE",
        data: {
            preload: true,
        }
    },
    {
        path: 'email-sent-successfully',
        component: EmailSentSuccessfullyComponent,
        title: "BSTP | EMAIL ENVOYÉ AVEC SUCCÈS",
        data: {
            preload: true,
        }
    },
    {
        path: 'payment-successful',
        component: PaymentSuccessfulComponent,
        title: "BSTP | PAIEMENT EFFECTUÉ AVEC SUCCÈS",
        data: {
            preload: true,
        }
    },

    {
        path: 'create-old-company',
        component: CreateOldCompanyComponent,
        title: "BSTP | AJOUTER UNE ANCIENNE ENTREPRISE",
        data: {
            preload: true,
        }
    },

    {
        path: 'maintenance',
        component: MaintenanceComponent,
        title: "ERREUR DE CONNEXION",
        data: {
            preload: true
        }
    },

    { path: "**", component: ErrorComponent, title: "BSTP | ERREUR 404" },
    {
        path: 'institution',
        loadChildren: () => import('./components/pages/institution/institution.module').then(m => m.InstitutionModule),
        title: 'Institution',
        canActivate: [AuthGuard, RolesGuard],
        data: {
            role: ['institute',]
        }
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy, initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
