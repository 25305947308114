import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppSetting } from 'src/models/app-setting';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {
  private appSettingSubject: BehaviorSubject<AppSetting>;
  public appSetting$: Observable<AppSetting>;

  constructor(private http: HttpClient) {
    this.appSettingSubject = new BehaviorSubject<AppSetting>(null);
    this.appSetting$ = this.appSettingSubject.asObservable();
  }

  public get value(): AppSetting {
    return this.appSettingSubject.value;
  }

  update(appSetting: AppSetting){
    this.appSettingSubject.next(appSetting);
  }

  get(): Observable<any> {  
    return this.http.get(`${environment.apiUrl}app_settings/`, { withCredentials: true }) as Observable<any>;
  }
  
}
