<!-- <div class="my-overlay" *ngIf="loading">
	<img src="./assets/img/loading.gif" width="70" alt="">
</div> -->

<!-- <div class="container" *ngIf="loading">
	<div class="row">
		<div id="loader">
    		<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="lading"></div>
		</div>
	</div>
</div> -->

<div class="text-center pb-100" *ngIf="loading">
	<i class="icofont-spinner-alt-6 icofont-3x icofont-spin"></i>
	<p>Chargement...</p>
</div>