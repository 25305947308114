<div *ngIf="request">
    <div class="page-title-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-text">
                        <h2> {{ request.type.name|uppercase }} </h2>
                        <ul>
                            <li><a routerLink="/">ACCUEIL</a></li>
                            <li><i class="icofont-simple-right"></i></li>
                            <li><a routerLink="/">APPELS D'OFFRES</a></li>
                            <li><i class="icofont-simple-right"></i></li>
                            <li> {{ request.type.name|uppercase }} </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="job-details-area pt-5">
        <div class="container">
            <div class="alert alert-success" role="alert" *ngIf="request.jour_restant == 0">
                <h4 class="alert-heading">Well done!</h4>
                <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p>
                <hr>
                <p class="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>
              </div>
            
            <div class="row mt-5">
                <div class="col-lg-8">
                    
                    <div class="job-details-item">
                        <div class="job-description">
                            <h2>{{ request.name }} - {{ request.reference }}</h2>
                        </div>
                        
                        <div class="job-description">
                            <h2>Demande sommaire</h2>
                            <p [innerHTML]="request.summary"></p>
                        </div>
    
                        <div class="job-description">
                            <h2>Detail de la demande</h2>
                            <p [innerHTML]="request.description"></p>
                        </div>

                        <div class="job-overview">
                            <ul>
                                <li style="padding-top: 5px; padding-bottom: 5px;" *ngFor="let file of request.attachements">
                                    <a [href]="file.document" target="_blank">
                                        <i class="icofont-file-pdf" style="font-size: 40px;"></i>
                                        <h2 class="mt-1">{{ file.name }}</h2>
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
    
                        <div class="text-center" *ngIf="request.modality == 'bstp' && request.jour_restant != 0">
                            <a style="cursor: pointer;" class="job-details-btn"  (click)="open(content)">SOUMISSIONNEZ MAINTENANT</a>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-4">
                    <div class="job-details-item">
                        <div class="job-overview">
                            <h3>Aperçu</h3>
    
                            <ul>
                                <li>
                                    <i class="icofont-wall-clock"></i>
                                    <p>Date de publication</p>
                                    <span> {{ request.created_at|date:'dd/MM/yyyy' }} </span>
                                </li>
                                
                                <li>
                                    <i class="icofont-wall-clock"></i>
                                    <p>Date limite</p>
                                    <span class="text-danger text-bold"> {{ request.expired_at|date:'dd/MM/yyyy' }} </span>
                                </li>
                                <li>
                                    <i class="icofont-calendar"></i>
                                    <p>Nombre de jours restants</p>
                                    <span> {{ request.jour_restant }} {{ request.jour_restant > 1 ? " jours" : " jour" }} </span>
                                </li>
                            </ul>
                        </div>
    
                        <div class="job-overview job-company" *ngIf="request.categories.length > 0">
                            <h3>Secteurs d'activité</h3>
    
                            <ul>
                                <li *ngFor="let category of request.categories">
                                    <i class="icofont-check-circled"></i>
                                    <span> {{ category.name }} </span>
                                </li>
                            </ul>
                        </div>
                        <div class="job-overview job-company">
                            <h3>Information de l'acheteur</h3>
    
                            <ul>
                                <li>
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <img [src]="request.buyer.logo" width="24" height="24">
                                        </div>
                                        <div class="col-lg-10">
                                            <h4> {{ request.buyer.name }} </h4>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <i class="icofont-location-pin"></i>
                                    <span> {{ request.buyer.address }} </span>
                                </li>
    
                                <li>
                                    <i class="icofont-ui-call"></i>
                                    <a href="tel:{{ request.buyer.phone_number }}"> {{ request.buyer.phone_number }} </a>
                                </li>
    
                                <li>
                                    <i class="icofont-ui-email"></i>
                                    <a href="mailto:{{ request.buyer.email }}"> {{ request.buyer.email }} </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
<app-loading [loading]="loading" />

<ng-template #content let-modal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">SOUMISSION DES DOCUMENTS</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<form action="" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="company">SELECTIONNER L'ENTREPRISE</label>
                        <select formControlName="company" id="company" class="form-select">
                            <option [value]="company.id" *ngFor="let company of companies"> {{ company.name }} </option>
                        </select>
                    </div>
                </div>
            </div>
            <div formArrayName="documents" *ngFor="let document of documents.controls; index as i">
                <div [formGroupName]="i" class="row">
                    <div class="col-lg-5">
                        <div class="form-group">
                            <label for="name" class="form-label">TITRE DU DOCUMENT</label>
                            <input type="text" class="form-control" formControlName="name">
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="form-group">
                            <label for="name" class="form-label">SELECTIONNEZ LE DOCUMENT</label>
                            <input type="file" class="form-control" accept=".pdf" (change)="onFileChange('document', i, $event)">
                        </div>
                    </div>
                    <div *ngIf="documents.length > 1" class="col-lg-2 mt-4">
                        <button style="padding: 5px;" (click)="removeDocument(i)" class="btn py-2"><img width="30" height="30" src="./assets/img/file-remove.svg" alt=""></button>
                    </div>
                </div>
                <hr />
            </div>
        </form>
        <button class="btn py-2" (click)="addDocument()"><img width="20" height="20" src="./assets/img/file-add.svg" alt=""> AJOUTER UN DOCUMENT</button>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="onSubmit()" [disabled]="form.invalid">ENVOYEZ MES DOCUMENTS</button>
	</div>
</ng-template>