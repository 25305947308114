<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>PAIEMENT EFFECTUÉ AVEC SUCCÈS</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>PAIEMENT EFFECTUÉ AVEC SUCCÈS</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="post-job-area pt-5 pb-100 mt-5">
    <div class="container text-left">
        <img src="assets/guides/04.png" alt="" width="150" />
        <div class="pt-5">
            <h4>Paiement Effectué avec succès</h4>
            <p>
                Votre paiement a été effectué avec succès.
            </p>
            <p>
                Nous vous remercions pour votre inscription et nous vous
                souhaitons la bienvenue.
            </p>
            <p>
                Cliquez sur le bouton "<strong>Finaliser</strong>" pour accéder à votre compte.
            </p>
            
            <div class="row pt-5">
                <div class="col-lg-6">
                    <button routerLink="/login" class="btn btn-green" type="submit">
                        Finaliser <i class="icofont-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
