import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Partenaire } from 'src/models/partenaire';

@Injectable({
  providedIn: 'root'
})
export class PartnershipService {
  private partenairesSubject: BehaviorSubject<Array<Partenaire>>;
  public partenaires$: Observable<Array<Partenaire>>;

  constructor(private http: HttpClient) { 
    this.partenairesSubject = new BehaviorSubject<Array<Partenaire>>([]);
    this.partenaires$ = this.partenairesSubject.asObservable();
  }

  public get value(): Array<Partenaire> {
    return this.partenairesSubject.value;
  }

  update(partenaires: Array<Partenaire>){
    this.partenairesSubject.next(partenaires);
  }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}partnership/`) as Observable<any>;
  }
}
